import React, { useState, useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import CountUp from "react-countup";
import ScrollAnimation from "react-animate-on-scroll";
import Web3 from "web3";
import { getCurAddr,getCurAddr1 } from "../action/Apicontroller";
import { toastAlert } from "../helper/toastAlert";
import config from "../config/config";
import { setWallet } from "../reducers/Actions";
import { type WalletClient, useWalletClient } from 'wagmi'
import { useSelector, useDispatch } from "react-redux";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}



export default function Home(props) {
  const [data, setdata] = useState({});
  const [providerValue, setproviderValue] = useState({signer:{},transport:{}});
  const dispatch = useDispatch()
  let chainid = config.networkVersion ;
  const { data: walletClient } = useWalletClient({ chainid })
  
  useEffect(() => {
    getdetails();
  }, [walletClient]);

  async function getdetails() {
    let datas = await getCurAddr();
    if (
      datas &&
      datas.address &&
      datas.address != undefined &&
      datas.address != null &&
      datas.address != "" &&
      localStorage.getItem("account")
    ) {
      console.log(datas,'datasdatas')
      setdata(datas);
      if (datas.userdetails && datas.userdetails.isExist) {
        window.location.href="/dashboard"
      } else {
      }
    }
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="page_header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 m-auto text-center">              
                <h1>
                  A Decentralized Networking Platform Based on Smart Contracts
                </h1>              
                <h5>
                  Set to transform the global community for economic change on
                  web3 technology with 100% Decentralized Automated System.
                </h5>              
            </div>
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        {/* Feature Section */}
        <section className="features_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-6 m-auto">
                <ScrollAnimation animateIn="zoomIn">
                  <h3 className="main_title_small text-center">
                    What we can do for you?
                  </h3>
                  <h2 className="main_title text-center">Features</h2>
                </ScrollAnimation>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <ScrollAnimation animateIn="flipInX">
                  <div className="primary_box features_box">
                    <div className="features_box_flex_title">
                      <h4>Smart Contract on Blockchain</h4>
                      <img
                        src={require("../assets/images/feature_icon_01.png")}
                        alt="feature"
                        className="img-fluid"
                      />
                    </div>
                    <p className="inner_para">
                      Distributed on BNB blockchain using a smart contract,
                      moreover it can't be hacked, edited or cancelled.
                      Britance.com will exist for ever until blockchain does.
                    </p>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-md-6 col-lg-4">
                <ScrollAnimation animateIn="flipInX">
                  <div className="primary_box features_box">
                    <div className="features_box_flex_title">
                      <h4>Web3 100% Automated System</h4>
                      <img
                        src={require("../assets/images/feature_icon_02.png")}
                        alt="feature"
                        className="img-fluid"
                      />
                    </div>
                    <p className="inner_para">
                      Britance System is created with a vision to make
                      economical growth to the each and every users in
                      Britance.com pay with web3 decentralised technology.
                    </p>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-md-6 col-lg-4">
                <ScrollAnimation animateIn="flipInX">
                  <div className="primary_box features_box">
                    <div className="features_box_flex_title">
                      <h4>Are the funds safe here</h4>
                      <img
                        src={require("../assets/images/feature_icon_03.png")}
                        alt="feature"
                        className="img-fluid"
                      />
                    </div>
                    <p className="inner_para">
                      Yes your funds are very safe and secured. The complete
                      system included the automated upgrades guranteeing from
                      BNB Blockchain. The possibility to be loose your funds is
                      zero.
                    </p>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}