import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Terms(props) {

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="inner_wrapper">
        <div className="container">
          <div className="dashboard_panel">
            <div className="row">
              <div className="col-md-12">
                <div className="title_flex justify-content-center pt-4">
                  <h2 className="inner_title">Terms & Privacy Policy</h2>
                </div>
                <div className="px-5">
                  <p>This <span className="orange_txt">BRITANCE.COM</span> smart contract (hereinafter referred as “contract”) is here by submit the following few lines for our community members for necessary information and strict compliance please. The contract is fully decentralised. The main motto of creating this contract is to create a massive community and thus the created community will drive the future of the BNB which in turn lead to massive growth of the BNB along with the alt coins. The smart contract is the platform which will thrive the crypto currencies to its peak by the help of the fully decentralised communities. The community is the one, which holds the corresponding crypto asset in its decentralised wallet or in some authentic places such as exchanges and etc., if the crypto asset of the concerned crypto currency i.e., here in this case “BNB” is kept hold by massive community members then automatically the demand and supply concept awoke and the value of BNB will be enhanced periodically.</p>
                  <p>Thus, this contract is in need of massive community which has to be build by the present community members by referring the crypto enthusiasts. Every crypto enthusiastic member has to purchase <span className="orange_txt">0.20 BNB</span> from the authentic platforms and join to this contract for best results. The upgradations whenever needed to be done with the profits/benefits got from this contract, if any of the community member is of the view that he/she has to upgrade without earnings, it is purely his/her opinion to do so. This contract does not constrain any community member to upgrade before earnings, thus it is advisable not to practice the same. </p>
                  <p>This contract is framed in such a way that massive community should join and earn the passive income according to their investments made. However, it is also a proven fact that the community members with huge and massive community will yield a good profit in the end.</p>
                  <p>Whenever it is in need of the upgradation, it is advisable only to perform then, because whatever the benefits promised according to this contract will be received only by the concerned contract, subject to the condition of the upgradation. If necessary, upgradation which is to be done, has not been invoked; then the benefits which has to be received will not carry forward to any level of the community members i.e., once it is yours, it will always be yours. In this case, you can see the benefits which you might get by upgrading, once you upgrade your contract you will automatically get the benefit which you owe. Thus, all the community members are here by informed to go through the above lines thoroughly and act accordingly.</p>
                </div>
              </div>
            </div>

            <div className="dashboard_top_wrap">
              <div className="row">
                <div className="col-md-12">
                  
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
