let NODE_ENV      = 'production';

let Front_URL     = '';
let Base_URL      = '';
let rpcURL        = '';
let decimalvalues = 1000000000000000000;
let toFixed       = 4;
let networkVersion= '';
let bscurl        = '';
let adminAddress  = ""
let Binancepaycontract = ""
let bnbtousd      = ""
let chainId       = ""
let Server_URL    = "";
let projectId="";

let toasterOption = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}

if (NODE_ENV === "production") {
    Front_URL          = 'https://www.britance.com/';
    Server_URL         = 'https://producationapi.britance.com/';
    networkVersion     = 56;
    rpcURL             = 'https://bsc-dataseed3.binance.org/' ;
    bscurl             = "https://bscscan.com/";
    adminAddress       = "0x7e365ebc89be8c21d340b6221f3757720a81ff69";
    Binancepaycontract = "0x1dd13F7f2Efeca79D4253E7960381813ED61Dd47";
    bnbtousd           = "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE";
    chainId            = 56;
    projectId          = "cb89ebb21cdccb2e1b591e189e27706a";//wallet connect
}
else if (NODE_ENV === "demo") {
    Front_URL          = 'https://binancepay.alwin.io/';
    Server_URL         = 'https://binancepayapi.alwin.io/';// "http://localhost:2053/";//'https://binancepayapi.alwin.io/';
    networkVersion     = 97;
    rpcURL             = 'https://data-seed-prebsc-1-s3.binance.org:8545/' ;
    bscurl             = "https://testnet.bscscan.com/";
    adminAddress       = "0xdC6A32D60002274A16A4C1E93784429D4F7D1C6a";
    Binancepaycontract = "0xb5ca1F9eb9B56640e80cB614E913B16Cf37DcD39";//"0xD686Ccd71984c512beecdE271Cb0B2289C2AF2a9";
    bnbtousd           = "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE";
    chainId            = 97;
    projectId          = "cb89ebb21cdccb2e1b591e189e27706a";//wallet connect
}
else {
     Front_URL          = 'https://binancepay.alwin.io/';
    Server_URL         = "http://localhost:2053/";//'https://binancepayapi.alwin.io/';
    networkVersion     = 97;
    rpcURL             = 'https://data-seed-prebsc-1-s3.binance.org:8545/' ;
    bscurl             = "https://testnet.bscscan.com/";
    adminAddress       = "0xdC6A32D60002274A16A4C1E93784429D4F7D1C6a";
    Binancepaycontract = "0xD686Ccd71984c512beecdE271Cb0B2289C2AF2a9";
    bnbtousd           = "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE";
    chainId            = 97;
    projectId          = "cb89ebb21cdccb2e1b591e189e27706a";//wallet connect
}

let key = {
    Front_URL           : Front_URL,
    Server_URL          : Server_URL,
    decimalvalues       : decimalvalues,
    toFixed             : toFixed,
    "rpcURL"            : rpcURL,
    networkVersion      : networkVersion,
    toasterOption       : toasterOption,
    adminAddress        : adminAddress,
    Binancepaycontract  : Binancepaycontract,
    bnbtousd            : bnbtousd,
    bscurl              : bscurl,
    chainId             : chainId,
    projectId             : projectId
};

export default key;
