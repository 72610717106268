import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { AnimatedTree } from "react-tree-graph";
import "react-tree-graph/dist/style.css";
import Web3 from "web3";
import { getCurAddr , levelupgrade , checkuser , updatemissed , get_levelcounts , get_planAlist , get_eligible , get_weekcount } from "../action/Apicontroller";
import { toastAlert } from "../helper/toastAlert";
import {
  Multicall,
  ContractCallResults,
  ContractCallContext,
} from 'ethereum-multicall';
import syncEach from 'sync-each';
import { type WalletClient, useWalletClient } from 'wagmi'
import binanceapi from "../ABI/binancepayABI";
import config from '../config/config';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import BigNumber from "bignumber.js";

let count = 0;

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Dashboard(props) {
  let chainid = config.networkVersion ;
  const { data: walletClient } = useWalletClient({ chainid })
  useEffect(() => {
    window.addEventListener("resize", larg);
    getdetails();
  }, [walletClient]);

  const [userdata,setdata] = useState({});
  const [withdrawprogress,setwithdrawprogress] = useState("false");
  const [mynetwork,setmynetwork]= useState([]);
  const [level1,setlevel1] = useState([]);
  const [level2,setlevel2] = useState([]);
  const [level3,setlevel3] = useState([]);
  const [level4,setlevel4] = useState([]);
  const [selected_id,setselected_id] = useState("");
  const [treeprogress,settreeprogress] = useState(false);
  const [levelcounts,setlevelcounts] = useState("");
  const [levelload,setlevelload] = useState(true);
  const [planrange,setplanrange] = useState([]);
  const [planprogress,setplanprogress] = useState(true);
  const [gpr1count,setgpr1count] = useState(0);
  const [weekcountb,setweekcountb] = useState(0);
  const [weekcounta,setweekcounta] = useState(0);
  const [gpr2count,setgpr2count] = useState(0);
  const [upgradeamount,setupgradeamount] = useState(0);
  const [upgradeearningamount,setupgradeearningamount] = useState(0);
  const [remainingamount,setremainingamount] = useState(0);

  async function getdetails(){
  //if(count == 0){
   count = 1;
   let datas = await getCurAddr();
   if(datas && datas.address && 
          datas.address!=undefined && 
          datas.address!=null && 
          datas.address!="" && localStorage.getItem("account")){
       setdata(datas);
       if(datas.userdetails && 
          datas.userdetails.isExist){
          let user_data = {
            address : datas.address
          }
          let cowunt = await get_weekcount(user_data);
          if(cowunt && cowunt.data && cowunt.data.data && cowunt.data.data.week_countA){
            setweekcounta(cowunt.data.data.week_countA);
          }
          if(cowunt && cowunt.data && cowunt.data.data && cowunt.data.data.week_countB){
            setweekcountb(cowunt.data.data.week_countB);
          }
          let Eligible_rec = await get_eligible();
          console.log(Eligible_rec,"============Eligible_recEligible_recEligible_recEligible_rec")
          if(Eligible_rec && Eligible_rec.data && Eligible_rec.data.data && Eligible_rec.data.data.one && Eligible_rec.data.data.one.length>0){
            setgpr1count(Eligible_rec.data.data.one.length)
          }else{
            setgpr1count(0)
          }
          if(Eligible_rec && Eligible_rec.data && Eligible_rec.data.data && Eligible_rec.data.data.two && Eligible_rec.data.data.two.length>0){
            setgpr2count(Eligible_rec.data.data.two.length)
          }else{
            setgpr2count(0)
          }
          let data = await checkuser(user_data);
          if(data && data.data && data.data.address){
            getuserdetails(datas.address)
          }else{
            getregister(datas);
            getuserdetails(datas.address)
          }
          let level_counts = await get_levelcounts(user_data);
          let planAlist = await get_planAlist(user_data);
          if(planAlist && planAlist.data && planAlist.data.records){
            setplanrange(planAlist.data.records);
            setplanprogress(false)
          }else{
            setplanrange("");
            setplanprogress(false)
          }
          if(level_counts && level_counts.data && level_counts.data.records){
            setlevelcounts(level_counts.data.records);
            setlevelload(false)
          }else{
            setlevelcounts("");
            setlevelload(false)
          }
       }else{
        window.location.href="/join-now";
       }
    }else{
      console.log(datas,"=localStorage.getItem===",localStorage.getItem("account"))
      //window.location.href="/";
    }
   //}
  }

  async function upgradeMannual(){
    if(buyprocessing == "false"){
      setbuyprocessing("true");
      try{
        let _level = 2;
        let datas = await getCurAddr();
        if(datas && datas.address && 
              datas.address!=undefined && 
              datas.address!=null && 
              datas.address!="" && localStorage.getItem("account")){
          if(datas.userdetails && 
              datas.userdetails.isExist){
              if(parseInt(datas.userdetails.currentLevel)+parseInt(1) == _level){
              let web3 =  new Web3(datas && datas.provider && datas.provider!=null && datas.provider!=undefined && datas.provider!=""?datas.provider:window.ethereum);
              var binanceContract = new web3.eth.Contract(binanceapi, config.Binancepaycontract);
              let levelprice = await binanceContract.methods.remainingAmountLevel2(localStorage.getItem("account")).call();
              levelprice =  (parseFloat(levelprice)  * 1e18) / 10000000
              let sendamt = await convert(levelprice);
              if(parseFloat(datas.bnbvalue)>parseFloat(levelprice)){
                let register = await binanceContract.methods.manualUpgrade().send({
                    from: datas.address,
                    value: sendamt.toString()
                })
                if(register && register.status && register.transactionHash){
                  let upgrade_data = {
                    address : datas.address,
                    level : _level
                  }
                  await levelupgrade(upgrade_data);
                  toastAlert('success', "Purchased Successfully", 'network');
                  setbuyprocessing("false");
                  window.location.reload();
                }else{
                  setbuyprocessing("false");
                  toastAlert('error', "Failed , try again later", 'network');
                }
              }else{
                setbuyprocessing("false");
                toastAlert('error', "Low Balance", 'network');
              }
            }else{
              setbuyprocessing("false");
              if(parseInt(datas.userdetails.currentLevel) > _level){
                toastAlert('error', "Incorrect Level", 'network');
              }else if(parseInt(datas.userdetails.currentLevel) <= _level){
                toastAlert('error', "Level already Purchased", 'network');
              }
            }
          }else{
            setbuyprocessing("false");
            toastAlert('error', "Join First to proceed Further", 'network');
            window.location.href="/join-now";
          }
        }else{
          setbuyprocessing("false");
          toastAlert('error', "Please select BSC chain network", 'network');
        }
      }catch(err){
        setbuyprocessing("false");
        console.log(err,"======err in buy")
      }
    }
  }

  async function getregister(datas){
    let web3 =  new Web3(datas && datas.provider && datas.provider!=null && datas.provider!=undefined && datas.provider!=""?datas.provider:window.ethereum);
    var binanceContract = new web3.eth.Contract(binanceapi, config.Binancepaycontract);
    let userrefdetails = await binanceContract.methods.planB(datas.address).call();
    let referralparent = await binanceContract.methods.userList(userrefdetails.referrerID).call();
    let rec = {
      address : datas.address,
      userid  : datas.userdetails.id,
      referralparent  : referralparent,
      level   : datas.userdetails.currentLevel
    }
    await updatemissed(rec);
  }

  async function larg() {
    setwidth(window.innerWidth - 50);
    setheight(window.innerHeight - 50);
  }

  const [selected, setSelected] = useState({});
  const [allselected, setallSelected] = useState({});
  const [width, setwidth] = useState(window.innerWidth - 50);
  const [height, setheight] = useState(window.innerHeight - 50);
  const [userdetails,setuserdetails] = useState({});
  const [totaluser,settotaluser] = useState(0);
  const [buyprocessing,setbuyprocessing] = useState("false");
  const [downlinefirst,setdownlinefirst] = useState([]);
  const [downlinesecond,setdownlinesecond] = useState([]);
  const [downlinethird,setdownlinethird] = useState([]);
  const [downlinefourth,setdownlinefourth] = useState([]);

  async function buynow(_level){
    if(buyprocessing == "false"){
      setbuyprocessing("true");
      try{
        let datas = await getCurAddr();
        if(datas && datas.address && 
              datas.address!=undefined && 
              datas.address!=null && 
              datas.address!="" && localStorage.getItem("account")){
          if(datas.userdetails && 
              datas.userdetails.isExist){
              if(parseInt(datas.userdetails.currentLevel)+parseInt(1) == _level){
              let web3 =  new Web3(datas && datas.provider && datas.provider!=null && datas.provider!=undefined && datas.provider!=""?datas.provider:window.ethereum);
              var binanceContract = new web3.eth.Contract(binanceapi, config.Binancepaycontract);
              let levelprice = await binanceContract.methods.LEVEL_PRICE(_level).call();
              levelprice =  (parseFloat(levelprice)  * 1e18) / 10000000
              let sendamt = await convert(levelprice);
              if(parseFloat(datas.bnbvalue)>parseFloat(levelprice)){
                let register = await binanceContract.methods.buyLevel(_level).send({
                    from: datas.address,
                    value: sendamt.toString()
                })
                if(register && register.status && register.transactionHash){
                  let upgrade_data = {
                    address : datas.address,
                    level : _level
                  }
                  await levelupgrade(upgrade_data);
                  toastAlert('success', "Purchased Successfully", 'network');
                  setbuyprocessing("false");
                  window.location.reload();
                }else{
                  setbuyprocessing("false");
                  toastAlert('error', "Failed , try again later", 'network');
                }
              }else{
                setbuyprocessing("false");
                toastAlert('error', "Low Balance", 'network');
              }
            }else{
              setbuyprocessing("false");
              if(parseInt(datas.userdetails.currentLevel) > _level){
                toastAlert('error', "Incorrect Level", 'network');
              }else if(parseInt(datas.userdetails.currentLevel) <= _level){
                toastAlert('error', "Level already Purchased", 'network');
              }
            }
          }else{
            setbuyprocessing("false");
            toastAlert('error', "Join First to proceed Further", 'network');
            window.location.href="/join-now";
          }
        }else{
          setbuyprocessing("false");
          toastAlert('error', "Please select BSC chain network", 'network');
        }
      }catch(err){
        setbuyprocessing("false");
        console.log(err,"======err in buy")
      }
    }
  }

   async function claimRewards(){
    setwithdrawprogress("true");
    try{
      let datas = await getCurAddr();
      if(datas && datas.address && 
            datas.address!=undefined && 
            datas.address!=null && 
            datas.address!="" && localStorage.getItem("account")){
        if(datas.userdetails && 
            datas.userdetails.isExist){
          if(parseFloat(datas.userdetails.earnedAmount) >0 ){
            let web3 =  new Web3(datas && datas.provider && datas.provider!=null && datas.provider!=undefined && datas.provider!=""?datas.provider:window.ethereum);
            var binanceContract = new web3.eth.Contract(binanceapi, config.Binancepaycontract);
            let register = await binanceContract.methods.claimRewards().send({
                from: datas.address
            })
            if(register && register.status && register.transactionHash){
              toastAlert('success', "Earnings Claimed Successfully", 'network');
              setwithdrawprogress("false");
              window.location.reload();
            }else{
              setwithdrawprogress("false");
              toastAlert('error', "Failed , try again later", 'network');
            }
          }else{
            setwithdrawprogress("false");
            toastAlert('error', "Insufficient Earnings to Withdraw", 'network');
          }
        }else{
          setwithdrawprogress("false");
          toastAlert('error', "Join First to proceed Further", 'network');
          window.location.href="/join-now";
        }
      }else{
        setwithdrawprogress("false");
        toastAlert('error', "Please select BSC chain network", 'network');
      }
    }catch(err){
      setwithdrawprogress("false");
      console.log(err,"======err in claim")
    }
  }

  async function copyToClipboard(e) {
    var textField = document.createElement("textarea");
    textField.innerText = config.Front_URL+"join-now/"+localStorage.getItem("account");
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toastAlert("success", "Copied Successfully", "success");
  }

  function convert(n){
    var sign = +n < 0 ? "-" : "",
    toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead,decimal,pow] = n.toString()
     .replace(/^-/,"")
     .replace(/^([0-9]+)(e.*)/,"$1.$2")
     .split(/e|\./);
     return +pow < 0
    ? sign + "0." + "0".repeat(Math.max(Math.abs(pow)-1 || 0, 0)) + lead + decimal
    : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow-decimal.length || 0, 0))) : (decimal.slice(0,+pow)+"."+decimal.slice(+pow)))
  }

  var buy_item_carousel = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,    
    centerPadding: '1px',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  async function getuserdetails(addr){
    settreeprogress(true)
    let datas = await getCurAddr();
    let web3call =  new Web3(datas && datas.provider && datas.provider!=null && datas.provider!=undefined && datas.provider!=""?datas.provider:window.ethereum);
    try{
    const multicall = new Multicall({
        web3Instance: web3call,
    });
    var contractCallContext = [
      {
          reference: "details",
          contractAddress: config.Binancepaycontract,
          abi: binanceapi,
          calls: [
              {
                  reference: "userprofile",
                  methodName: "users",
                  methodParameters: [localStorage.getItem("account")],
              },
              {
                  reference: "totaluser",
                  methodName: "totalUsers",
                  methodParameters: [],
              },
              {
                  reference: "viewplanBUserReferral",
                  methodName: "viewplanBUserReferral",
                  methodParameters: [localStorage.getItem("account")],
              },
              {
                  reference: "viewUserReferral",
                  methodName: "viewUserReferral",
                  methodParameters: [localStorage.getItem("account")],
              },
              {
                  reference: "missedearing2",
                  methodName: "getmissedvalue",
                  methodParameters: [localStorage.getItem("account"),2],
              },
              {
                  reference: "missedearing3",
                  methodName: "getmissedvalue",
                  methodParameters: [localStorage.getItem("account"),3],
              },
              {
                  reference: "missedearing4",
                  methodName: "getmissedvalue",
                  methodParameters: [localStorage.getItem("account"),4],
              },
              {
                  reference: "missedearing5",
                  methodName: "getmissedvalue",
                  methodParameters: [localStorage.getItem("account"),5],
              },
              {
                  reference: "missedearing6",
                  methodName: "getmissedvalue",
                  methodParameters: [localStorage.getItem("account"),6],
              },
              {
                  reference: "missedearing7",
                  methodName: "getmissedvalue",
                  methodParameters: [localStorage.getItem("account"),7],
              },
              {
                  reference: "missedearing8",
                  methodName: "getmissedvalue",
                  methodParameters: [localStorage.getItem("account"),8],
              },
               {
                  reference: "viewplanBUserReferral",
                  methodName: "viewplanBUserReferral",
                  methodParameters: [localStorage.getItem("account")],
              },
              {
                  reference: "upgradeAmount",
                  methodName: "upgradeAmount",
                  methodParameters: [localStorage.getItem("account")],
              },
          ],
      },
    ];
    const results = await multicall.call(contractCallContext);
    var userprofile = await getFormatMulticall(results, "userprofile", 0);
    var total_user = await getFormatMulticall(results, "totaluser", 0);
    var directreferral = await getFormatMulticall(results, "viewplanBUserReferral", 0);
    var downlinenode = await getFormatMulticall(results, "viewUserReferral", 0);
    var missedearing2 = await getFormatMulticall(results, "missedearing2", 0);
    var missedearing3 = await getFormatMulticall(results, "missedearing3", 0);
    var missedearing4 = await getFormatMulticall(results, "missedearing4", 0);
    var missedearing5 = await getFormatMulticall(results, "missedearing5", 0);
    var missedearing6 = await getFormatMulticall(results, "missedearing6", 0);
    var missedearing7 = await getFormatMulticall(results, "missedearing7", 0);
    var missedearing8 = await getFormatMulticall(results, "missedearing8", 0);
    var myreferral = await getFormatMulticall(results, "viewplanBUserReferral", 0);
    var my_upgradeamt = await getFormatMulticall(results, "upgradeAmount", 0);
    let usercount = await bignumber(total_user[0]);
    settotaluser(usercount);
    let upgradeamt = await bignumber(my_upgradeamt[0]);
    setupgradeamount(upgradeamt);
    if(userprofile[0]){
      let userexist = true;
      let userid = await bignumber(userprofile[1]);
      let currlevel = await bignumber(userprofile[3]);
      let earned_Amount = await bignumber(userprofile[4]);
      let total_earned_Amount = await bignumber(userprofile[5]);
      let royalityincome = await bignumber(userprofile[6]);
      let royalityincome1 = await bignumber(userprofile[7]);  
      let royalityincome2 = await bignumber(userprofile[8]); 
      let l2_missedearnings = await bignumber(missedearing2[0]); 
      let l3_missedearnings = await bignumber(missedearing3[0]); 
      let l4_missedearnings = await bignumber(missedearing4[0]); 
      let l5_missedearnings = await bignumber(missedearing5[0]); 
      let l6_missedearnings = await bignumber(missedearing6[0]); 
      let l7_missedearnings = await bignumber(missedearing7[0]); 
      let l8_missedearnings = await bignumber(missedearing8[0]);  
      let data_user = {
        "userexist" : userexist,
        "userid" : userid,
        "earnedAmount" : earned_Amount,
        "totalearnedAmount": total_earned_Amount,
        "currlevel" : parseInt(currlevel),
        "directreferral" : directreferral,
        "downlinenode": downlinenode,
        "royalityincome": royalityincome,
        "globalroyality1income": royalityincome1,
        "globalroyality2income": royalityincome2,
        "l2_missedearnings" :l2_missedearnings,
        "l3_missedearnings" :l3_missedearnings,
        "l4_missedearnings" :l4_missedearnings,
        "l5_missedearnings" :l5_missedearnings,
        "l6_missedearnings" :l6_missedearnings,
        "l7_missedearnings" :l7_missedearnings,
        "l8_missedearnings" :l8_missedearnings,
      }
      setuserdetails(data_user)
      if(currlevel<2){
        var binanceContract = new web3call.eth.Contract(binanceapi, config.Binancepaycontract);
        let remainamt = await binanceContract.methods.upgradeAmount(localStorage.getItem("account")).call();
        setupgradeearningamount(remainamt);
        let remainamt1 = await binanceContract.methods.remainingAmountLevel2(localStorage.getItem("account")).call();
        setremainingamount(remainamt1);
        
      }
      let _my_ref =[];
      if(myreferral.length>0){
        for(let noderef in myreferral){
           var _myref = [
            {
                reference: "details",
                contractAddress: config.Binancepaycontract,
                abi: binanceapi,
                calls: [
                    {
                        reference: "userprofile",
                        methodName: "users",
                        methodParameters: [myreferral[noderef]],
                    },
                    {
                        reference: "viewUserReferral",
                        methodName: "viewUserReferral",
                        methodParameters: [myreferral[noderef]],
                    },
                ],
            },
          ];
          const _results = await multicall.call(_myref);
          var _userprofile = await getFormatMulticall(_results, "userprofile", 0);
          var _downlinenode = await getFormatMulticall(_results, "viewUserReferral", 0);
          let _userid = await bignumber(_userprofile[1]);
          let _currlevel = await bignumber(_userprofile[3]);
          let _downline = {
            "address":myreferral[noderef],
            "level":_currlevel,
            "userid":_userid,
            "downlinenode":_downlinenode
          }
          _my_ref.push(_downline);
          if(parseFloat(noderef)+1==myreferral.length){
            setmynetwork(_my_ref);
          }
        }
      }

      //******downlinecalculation  1************//
      let downline1=[];
      let downline2arr=[];
      let downline3arr=[];
      let downline4arr=[];
      let downline5arr=[];
      let downline2=[];
      let downline3=[];
      let downline4=[];
      if(downlinenode.length>0){
        for(let node in downlinenode){
           var downlineCallContext = [
            {
                reference: "details",
                contractAddress: config.Binancepaycontract,
                abi: binanceapi,
                calls: [
                    {
                        reference: "userprofile",
                        methodName: "users",
                        methodParameters: [downlinenode[node]],
                    },
                    {
                        reference: "viewUserReferral",
                        methodName: "viewUserReferral",
                        methodParameters: [downlinenode[node]],
                    },
                ],
            },
          ];
          const results1 = await multicall.call(downlineCallContext);
          var userprofile1 = await getFormatMulticall(results1, "userprofile", 0);
          var downlinenode1 = await getFormatMulticall(results1, "viewUserReferral", 0);
          let userid1 = await bignumber(userprofile1[1]);
          let currlevel1 = await bignumber(userprofile1[3]);
          let downline_1 = {
            "address":downlinenode[node],
            "level":currlevel1,
            "userid":userid1,
            "downlinenode":downlinenode1
          }
          downline1.push(downline_1);
          downline2arr = downline2arr.concat(downlinenode1);
          if(parseFloat(node)+1==downlinenode.length){
            setdownlinefirst(downline1);
          }
        }
      }
      //*******downline2*******//
       if(downline2arr.length>0){
        for(let node1 in downline2arr){
           var downlineCallContext0 = [
            {
                reference: "details",
                contractAddress: config.Binancepaycontract,
                abi: binanceapi,
                calls: [
                    {
                        reference: "userprofile",
                        methodName: "users",
                        methodParameters: [downline2arr[node1]],
                    },
                    {
                        reference: "viewUserReferral",
                        methodName: "viewUserReferral",
                        methodParameters: [downline2arr[node1]],
                    },
                ],
            },
          ];
          const results2 = await multicall.call(downlineCallContext0);
          var userprofile2 = await getFormatMulticall(results2, "userprofile", 0);
          var downlinenode2 = await getFormatMulticall(results2, "viewUserReferral", 0);
          let userid2 = await bignumber(userprofile2[1]);
          let currlevel2 = await bignumber(userprofile2[3]);
          let downline_2 = {
            "address":downline2arr[node1],
            "level":currlevel2,
            "userid":userid2,
            "downlinenode":downlinenode2
          }
          downline2.push(downline_2);
          downline3arr = downline3arr.concat(downlinenode2);
          if(parseFloat(node1)+1==downline2arr.length){
            setdownlinesecond(downline2);
          }
        }
      }

      //************downline3******************************//
       if(downline3arr.length>0){
        for(let node2 in downline3arr){
           var downlineCallContext1 = [
            {
                reference: "details",
                contractAddress: config.Binancepaycontract,
                abi: binanceapi,
                calls: [
                    {
                        reference: "userprofile",
                        methodName: "users",
                        methodParameters: [downline3arr[node2]],
                    },
                    {
                        reference: "viewUserReferral",
                        methodName: "viewUserReferral",
                        methodParameters: [downline3arr[node2]],
                    },
                ],
            },
          ];
          const results3 = await multicall.call(downlineCallContext1);
          var userprofile3 = await getFormatMulticall(results3, "userprofile", 0);
          var downlinenode3 = await getFormatMulticall(results3, "viewUserReferral", 0);
          let userid3 = await bignumber(userprofile3[1]);
          let currlevel3 = await bignumber(userprofile3[3]);
          let downline_3 = {
            "address":downline3arr[node2],
            "level":currlevel3,
            "userid":userid3,
            "downlinenode":downlinenode3
          }
          downline3.push(downline_3);
          downline4arr = downline4arr.concat(downlinenode3);
          if(parseFloat(node2)+1==downline3arr.length){
            setdownlinethird(downline3);
          }
        }
      }

      //************downline4******************************//
       if(downline4arr.length>0){
        for(let node3 in downline4arr){
           var downlineCallContext3 = [
            {
                reference: "details",
                contractAddress: config.Binancepaycontract,
                abi: binanceapi,
                calls: [
                    {
                        reference: "userprofile",
                        methodName: "users",
                        methodParameters: [downline4arr[node3]],
                    },
                    {
                        reference: "viewUserReferral",
                        methodName: "viewUserReferral",
                        methodParameters: [downline4arr[node3]],
                    },
                ],
            },
          ];
          const results4 = await multicall.call(downlineCallContext3);
          var userprofile4 = await getFormatMulticall(results4, "userprofile", 0);
          var downlinenode4 = await getFormatMulticall(results4, "viewUserReferral", 0);
          let userid4 = await bignumber(userprofile4[1]);
          let currlevel4 = await bignumber(userprofile4[3]);
          let downline_4 = {
            "address":downline4arr[node3],
            "level":currlevel4,
            "userid":userid4,
            "downlinenode":downlinenode4
          }
          downline4.push(downline_4);
          downline5arr = downline5arr.concat(downlinenode4);
          if(parseFloat(node3)+1==downline4arr.length){
            setdownlinefourth(downline4);
          }
        }
      }
      //****************************2nd level tree************************//
      // let line1arr=[];
      // if(downline1.length>0){
      //   for(let y in downline1){
      //     if(downline1[y].downlinenode.length>0){
      //       let subline=[];
      //       for(let z in downline1[y].downlinenode){
      //         var IndexVal = downline2.findIndex(val => val.address == downline1[y].downlinenode[z]);
      //         let user1 = {
      //           name : "ID "+downline2[IndexVal].userid+" ("+downline2[IndexVal].level+")"
      //         }
      //         subline.push(user1);
      //         if(parseInt(z)+1==downline1[y].downlinenode.length){
      //           let mainline = {
      //             "name": "ID "+downline1[y].userid+" ("+downline1[y].level+")",
      //             "children": subline
      //           }
      //           line1arr.push(mainline);
      //         }
      //       }
      //     }else{
      //       let mainline = {
      //           "name": "ID "+downline1[y].userid+" ("+downline1[y].level+")",
      //           "children": []
      //         }
      //         line1arr.push(mainline);
      //     }
      //     if(parseInt(y)+1==downline1.length){
      //       let mainlinearr = {
      //         name : "ID "+userid +" ("+currlevel+")",
      //         "children":line1arr
      //       }
      //       setSelected(mainlinearr);
      //       setallSelected(mainlinearr);
      //       let my_network = downline1.concat(downline2);
      //       //setmynetwork(my_network);
      //     }
      //   }
      // }else{
      //   let mainlinearr = {
      //     name : "ID "+userid +" ("+currlevel+")",
      //     "children":[]
      //   }
      //   setSelected(mainlinearr);
      //   setallSelected(mainlinearr);
      //   let my_network = downline1.concat(downline2);
      //   setmynetwork(my_network);
      // }

      // //************************3rd level tree ************************************//
      // let line1arr=[];
      // if(downline1.length>0){
      //   syncEach(downline1, async function (items,next) {
      //     if(items && items.downlinenode && items.downlinenode.length>0){
      //       let subline=[];
      //       syncEach(items.downlinenode, async function (subitems,next1) {
      //         var IndexVal = downline2.findIndex(val => val.address == subitems);
      //         if(IndexVal>=0 && downline2[IndexVal].downlinenode && downline2[IndexVal].downlinenode.length>0){
      //           let subline2 = [];
      //           syncEach(downline2[IndexVal].downlinenode, async function (thir_items,next2) {
      //             var IndexVal = downline3.findIndex(val => val.address == thir_items);
      //                 let user12 = {
      //                     name : "ID "+downline3[IndexVal].userid+" ("+downline3[IndexVal].level+")"
      //                 }
      //                 subline2.push(user12);
      //                 next2();
      //           },function( error , success){
      //             let mainline = {
      //                     "name": "ID "+downline2[IndexVal].userid+" ("+downline2[IndexVal].level+")",
      //                     "children": subline2
      //                   }
      //                   subline.push(mainline);
      //             next1();
      //           })
      //         }else{
      //           var IndexVal = downline2.findIndex(val => val.address == subitems);
      //               let user1 = {
      //                 name : "ID "+downline2[IndexVal].userid+" ("+downline2[IndexVal].level+")"
      //               }
      //               subline.push(user1);
      //           next1();
      //         }
      //       },function (errs,t_Items) {
      //         let mainline = {
      //               "name": "ID "+items.userid+" ("+items.level+")",
      //               "children": subline
      //             }
      //             console.log("push33")
      //             line1arr.push(mainline);
      //         next();
      //       })
      //     }else{
      //         let mainline = {
      //               "name": "ID "+items.userid+" ("+items.level+")",
      //               "children": []
      //             }
      //             line1arr.push(mainline);
      //             next();
      //       }
      //     },function (err,transformedItems) {
      //           let mainlinearr = {
      //             name : "ID "+userid +" ("+currlevel+")",
      //             "children":line1arr
      //           }
      //           setSelected(mainlinearr);
      //           setallSelected(mainlinearr);
      //           settreeprogress(false)
      //           let my_network = downline1.concat(downline2);
      //   })
      //   }else{
      //         let mainlinearr = {
      //           name : "ID "+userid +" ("+currlevel+")",
      //           "children":[]
      //         }
      //         setSelected(mainlinearr);
      //         setallSelected(mainlinearr);
      //         settreeprogress(false)
      //         let my_network = downline1.concat(downline2);
      //         setmynetwork(my_network);
      //   }

      //**************************4th level tree*********************//
      let line1arr=[];
      if(downline1.length>0){
        syncEach(downline1, async function (items,next) {
          if(items && items.downlinenode && items.downlinenode.length>0){
            let subline=[];
            syncEach(items.downlinenode, async function (subitems,next1) {
              let IndexVal = downline2.findIndex(val => val.address == subitems);
              if(IndexVal>=0 && downline2[IndexVal].downlinenode && downline2[IndexVal].downlinenode.length>0){
                let subline2 = [];
                syncEach(downline2[IndexVal].downlinenode, async function (thir_items,next2) {
                  let IndexVal1 = downline3.findIndex(val => val.address == thir_items);
                  if(IndexVal1>=0 && downline3[IndexVal1].downlinenode && downline3[IndexVal1].downlinenode.length>0){
                      let subline3 = [];
                      syncEach(downline3[IndexVal1].downlinenode, async function (fourth_items,next3) {
                        let Index_Val = downline4.findIndex(val => val.address == fourth_items);
                            let user22 = {
                                name : "ID "+downline4[Index_Val].userid+" ("+downline4[Index_Val].level+")"
                            }
                            subline3.push(user22);
                            next3();
                      },function( error , success){
                        let mainline = {
                                "name": "ID "+downline3[IndexVal1].userid+" ("+downline3[IndexVal1].level+")",
                                "children": subline3
                              }
                              setlevel3(mainline);
                              subline2.push(mainline);
                        next2();
                      })
                    }else{
                      let IndexVal = downline3.findIndex(val => val.address == thir_items);
                          let user1 = {
                            name : "ID "+downline3[IndexVal].userid+" ("+downline3[IndexVal].level+")"
                          }
                          subline2.push(user1);
                      next2();
                    }
                      // let user12 = {
                      //     name : "ID "+downline3[IndexVal].userid+" ("+downline3[IndexVal].level+")"
                      // }
                      // subline2.push(user12);
                      // next2();
                },function( error , success){
                  let mainline = {
                          "name": "ID "+downline2[IndexVal].userid+" ("+downline2[IndexVal].level+")",
                          "children": subline2
                        }
                        setlevel2(mainline)
                        subline.push(mainline);
                  next1();
                })
              }else{
                var IndexValue = downline2.findIndex(val => val.address == subitems);
                    let user1 = {
                      name : "ID "+downline2[IndexValue].userid+" ("+downline2[IndexValue].level+")"
                    }
                    subline.push(user1);
                next1();
              }
            },function (errs,t_Items) {
              let mainline = {
                    "name": "ID "+items.userid+" ("+items.level+")",
                    "children": subline
                  }
                  setlevel1(mainline)
                  line1arr.push(mainline);
              next();
            })
          }else{
              let mainline = {
                    "name": "ID "+items.userid+" ("+items.level+")",
                    "children": []
                  }
                  setlevel1(mainline);
                  setlevel2([])
                  line1arr.push(mainline);
                  next();
            }
          },function (err,transformedItems) {
                let mainlinearr = {
                  name : "ID "+userid +" ("+currlevel+")",
                  "children":line1arr
                }
                setSelected(mainlinearr);
                setallSelected(mainlinearr);
                settreeprogress(false)
                let my_network = downline1.concat(downline2);
        })
        }else{
              let mainlinearr = {
                name : "ID "+userid +" ("+currlevel+")",
                "children":[]
              }
              setSelected(mainlinearr);
              setallSelected(mainlinearr);
              settreeprogress(false);
              let my_network = downline1.concat(downline2);
              setmynetwork(my_network);
        }
    }
  }catch(err){
    console.log(err,"=====errr")
  }
  }

  async function bignumber(inputs){
    let ten =new BigNumber(inputs.hex,16);
    var value = ten.toString(10);
    return value;
  }

  function getFormatMulticall(results, name, pos) {
    try {
        var index = results && results.results && results.results.details && 
            results.results.details.callsReturnContext &&
            results.results.details.callsReturnContext.findIndex(val => val.reference == name);
        var returnVal = results.results.details.callsReturnContext[index] &&
            results.results.details.callsReturnContext[index].returnValues  ?
            results.results.details.callsReturnContext[index].returnValues : "";
        return returnVal;
    } catch (err) {
        console.log(err,"==+++++++")
        return "";
    }
  }

  async function handleClick(event, node) {
    if(node && node!=null && node!=undefined && node!=""){
      let myArray = node.split(" ");
      let userid = myArray[1];
      if(selected_id==userid){
        setSelected(allselected);
        setselected_id("");
      }else{
        settreeprogress(true)
        setselected_id(userid)
        let datas = await getCurAddr();
        let web3 =  new Web3(datas && datas.provider && datas.provider!=null && datas.provider!=undefined && datas.provider!=""?datas.provider:window.ethereum);
        const multicall = new Multicall({
            web3Instance: web3,
        });
        var binanceContract = new web3.eth.Contract(binanceapi, config.Binancepaycontract);
        let c_address = await binanceContract.methods.planBuserList(userid).call();
        let downlinenode = await binanceContract.methods.viewUserReferral(c_address).call();
        let leveldetails = await binanceContract.methods.users(c_address).call();
        let currlevel = leveldetails && leveldetails.currentLevel
        let downline1=[];
        let downline2arr=[];
        let downline3arr=[];
        let downline4arr=[];
        let downline5arr=[];
        let downline2=[];
        let downline3=[];
        let downline4=[];
        if(downlinenode.length>0){
          for(let node in downlinenode){
             var downlineCallContext = [
              {
                  reference: "details",
                  contractAddress: config.Binancepaycontract,
                  abi: binanceapi,
                  calls: [
                      {
                          reference: "userprofile",
                          methodName: "users",
                          methodParameters: [downlinenode[node]],
                      },
                      {
                          reference: "viewUserReferral",
                          methodName: "viewUserReferral",
                          methodParameters: [downlinenode[node]],
                      },
                  ],
              },
            ];
            const results1 = await multicall.call(downlineCallContext);
            var userprofile1 = await getFormatMulticall(results1, "userprofile", 0);
            var downlinenode1 = await getFormatMulticall(results1, "viewUserReferral", 0);
            let userid1 = await bignumber(userprofile1[1]);
            let currlevel1 = await bignumber(userprofile1[3]);
            let downline_1 = {
              "address":downlinenode[node],
              "level":currlevel1,
              "userid":userid1,
              "downlinenode":downlinenode1
            }
            downline1.push(downline_1);
            downline2arr = downline2arr.concat(downlinenode1);
            if(parseFloat(node)+1==downlinenode.length){
              setdownlinefirst(downline1);
            }
          }
        }
        //*******downline2*******//
         if(downline2arr.length>0){
          for(let node1 in downline2arr){
             var downlineCallContext0 = [
              {
                  reference: "details",
                  contractAddress: config.Binancepaycontract,
                  abi: binanceapi,
                  calls: [
                      {
                          reference: "userprofile",
                          methodName: "users",
                          methodParameters: [downline2arr[node1]],
                      },
                      {
                          reference: "viewUserReferral",
                          methodName: "viewUserReferral",
                          methodParameters: [downline2arr[node1]],
                      },
                  ],
              },
            ];
            const results2 = await multicall.call(downlineCallContext0);
            var userprofile2 = await getFormatMulticall(results2, "userprofile", 0);
            var downlinenode2 = await getFormatMulticall(results2, "viewUserReferral", 0);
            let userid2 = await bignumber(userprofile2[1]);
            let currlevel2 = await bignumber(userprofile2[3]);
            let downline_2 = {
              "address":downline2arr[node1],
              "level":currlevel2,
              "userid":userid2,
              "downlinenode":downlinenode2
            }
            downline2.push(downline_2);
            downline3arr = downline3arr.concat(downlinenode2);
            if(parseFloat(node1)+1==downline2arr.length){
              setdownlinesecond(downline2);
            }
          }
        }

        //************downline3******************************//
         if(downline3arr.length>0){
          for(let node2 in downline3arr){
             var downlineCallContext1 = [
              {
                  reference: "details",
                  contractAddress: config.Binancepaycontract,
                  abi: binanceapi,
                  calls: [
                      {
                          reference: "userprofile",
                          methodName: "users",
                          methodParameters: [downline3arr[node2]],
                      },
                      {
                          reference: "viewUserReferral",
                          methodName: "viewUserReferral",
                          methodParameters: [downline3arr[node2]],
                      },
                  ],
              },
            ];
            const results3 = await multicall.call(downlineCallContext1);
            var userprofile3 = await getFormatMulticall(results3, "userprofile", 0);
            var downlinenode3 = await getFormatMulticall(results3, "viewUserReferral", 0);
            let userid3 = await bignumber(userprofile3[1]);
            let currlevel3 = await bignumber(userprofile3[3]);
            let downline_3 = {
              "address":downline3arr[node2],
              "level":currlevel3,
              "userid":userid3,
              "downlinenode":downlinenode3
            }
            downline3.push(downline_3);
            downline4arr = downline4arr.concat(downlinenode3);
            if(parseFloat(node2)+1==downline3arr.length){
              setdownlinethird(downline3);
            }
          }
        }

         //************downline4******************************//
       if(downline4arr.length>0){
        for(let node3 in downline4arr){
           var downlineCallContext3 = [
            {
                reference: "details",
                contractAddress: config.Binancepaycontract,
                abi: binanceapi,
                calls: [
                    {
                        reference: "userprofile",
                        methodName: "users",
                        methodParameters: [downline4arr[node3]],
                    },
                    {
                        reference: "viewUserReferral",
                        methodName: "viewUserReferral",
                        methodParameters: [downline4arr[node3]],
                    },
                ],
            },
          ];
          const results4 = await multicall.call(downlineCallContext3);
          var userprofile4 = await getFormatMulticall(results4, "userprofile", 0);
          var downlinenode4 = await getFormatMulticall(results4, "viewUserReferral", 0);
          let userid4 = await bignumber(userprofile4[1]);
          let currlevel4 = await bignumber(userprofile4[3]);
          let downline_4 = {
            "address":downline4arr[node3],
            "level":currlevel4,
            "userid":userid4,
            "downlinenode":downlinenode4
          }
          downline4.push(downline_4);
          downline5arr = downline5arr.concat(downlinenode4);
          if(parseFloat(node3)+1==downline4arr.length){
            setdownlinefourth(downline4);
          }
        }
      }

      let line1arr=[];
      if(downline1.length>0){
        syncEach(downline1, async function (items,next) {
          if(items && items.downlinenode && items.downlinenode.length>0){
            let subline=[];
            syncEach(items.downlinenode, async function (subitems,next1) {
              let IndexVal = downline2.findIndex(val => val.address == subitems);
              if(IndexVal>=0 && downline2[IndexVal].downlinenode && downline2[IndexVal].downlinenode.length>0){
                let subline2 = [];
                syncEach(downline2[IndexVal].downlinenode, async function (thir_items,next2) {
                  let IndexVal1 = downline3.findIndex(val => val.address == thir_items);
                  if(IndexVal1>=0 && downline3[IndexVal1].downlinenode && downline3[IndexVal1].downlinenode.length>0){
                      let subline3 = [];
                      syncEach(downline3[IndexVal1].downlinenode, async function (fourth_items,next3) {
                        let Index_Val = downline4.findIndex(val => val.address == fourth_items);
                            let user22 = {
                                name : "ID "+downline4[Index_Val].userid+" ("+downline4[Index_Val].level+")"
                            }
                            subline3.push(user22);
                            next3();
                      },function( error , success){
                        let mainline = {
                                "name": "ID "+downline3[IndexVal1].userid+" ("+downline3[IndexVal1].level+")",
                                "children": subline3
                              }
                              setlevel3(mainline);
                              subline2.push(mainline);
                        next2();
                      })
                    }else{
                      let IndexVal = downline3.findIndex(val => val.address == thir_items);
                          let user1 = {
                            name : "ID "+downline3[IndexVal].userid+" ("+downline3[IndexVal].level+")"
                          }
                          subline2.push(user1);
                      next2();
                    }
                      // let user12 = {
                      //     name : "ID "+downline3[IndexVal].userid+" ("+downline3[IndexVal].level+")"
                      // }
                      // subline2.push(user12);
                      // next2();
                },function( error , success){
                  let mainline = {
                          "name": "ID "+downline2[IndexVal].userid+" ("+downline2[IndexVal].level+")",
                          "children": subline2
                        }
                        setlevel2(mainline)
                        subline.push(mainline);
                  next1();
                })
              }else{
                var IndexValue = downline2.findIndex(val => val.address == subitems);
                    let user1 = {
                      name : "ID "+downline2[IndexValue].userid+" ("+downline2[IndexValue].level+")"
                    }
                    subline.push(user1);
                next1();
              }
            },function (errs,t_Items) {
              let mainline = {
                    "name": "ID "+items.userid+" ("+items.level+")",
                    "children": subline
                  }
                  setlevel1(mainline)
                  console.log("push33")
                  line1arr.push(mainline);
              next();
            })
          }else{
              let mainline = {
                    "name": "ID "+items.userid+" ("+items.level+")",
                    "children": []
                  }
                  setlevel1(mainline);
                  setlevel2([])
                  line1arr.push(mainline);
                  next();
            }
          },function (err,transformedItems) {
                let mainlinearr = {
                  name : "ID "+userid +" ("+currlevel+")",
                  "children":line1arr
                }
                setSelected(mainlinearr);
                settreeprogress(false)
        })
        }else{
              let mainlinearr = {
                name : "ID "+userid +" ("+currlevel+")",
                "children":[]
              }
              setSelected(mainlinearr);
              settreeprogress(false);
        }
        }
      }
  }

  //****************mynetwork*********************//

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="inner_wrapper">
        <div className="container">
          <div className="dashboard_panel">
            <div className="dasbox">
            <div className="row">
              <div className="col-md-12">
                <div className="title_flex">
                  <h2 className="inner_title">Dashboard</h2>
                  <div className="wallet_div">
                    <label>Affiliate Link:</label>
                    <div className="inp_grp">
                      <input
                        type="text"
                        className="form-control primary_inp"
                        value={config.Front_URL+"join-now/"+localStorage.getItem("account")}
                      />
                      <button type="button" className="round_btn primary_btn" onClick={copyToClipboard}><img src={require("../assets/images/copy_icon.png")} className="copy_icon" alt="Copy" /></button>
                    </div>
                  </div>
                  {/* <p className="last_login_details">Last Login: 20 Oct 2022  14:40 PM</p> */}
                </div>
              </div>
            </div>
            <div className="dashboard_top_wrap">
              <div className="row">
                <div className="col-lg-3">
                  <div className="leftbox">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="primary_box dash_box">
                        <div className="dashbox_middle_top">
                          <div>
                            <div>
                              <h2> {userdetails && userdetails.userid?userdetails.userid:""}</h2>
                              <h4 className="mb-0">My ID</h4>
                            </div>
                            <div className=""><img src={require("../assets/images/dash_icon_04.png")} className="img-fluid" alt="Copy" /></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="primary_box dash_box">
                        <div className="dashbox_middle_top">
                          <div>
                            <div>
                              <h2>{totaluser ? totaluser : 0}</h2>
                              <h4>All Participants</h4>
                            </div>
                            <div className=""><img src={require("../assets/images/dash_icon_01.png")} className="img-fluid" alt="Copy" /></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="primary_box dash_box">
                        <div className="dashbox_middle_top">
                          <div>
                            <div>
                              <h2>{gpr1count ? gpr1count : 0}</h2>
                              <h4>Global Royalty 1 Eligible Participants</h4>
                            </div>
                            <div className=""><img src={require("../assets/images/group.png")} className="img-fluid" alt="Copy" /></div>
                          </div>
                        </div>
                      </div>
                    </div>
                     <div className="col-lg-12">
                      <div className="primary_box dash_box">
                        <div className="dashbox_middle_top">
                          <div>
                            <div>
                              <h2>{gpr2count ? gpr2count : 0}</h2>
                              <h4>Global Royalty 2 Eligible Participants</h4>
                            </div>
                            <div className=""><img src={require("../assets/images/group.png")} className="img-fluid" alt="Copy" /></div>
                          </div>
                        </div>
                      </div>
                    </div> 
                    <div className="col-lg-12">
                      <div className="primary_box dash_box">
                        <div className="dashbox_middle_top">
                          <div>
                            <div>
                              <h2>{userdetails && userdetails.totalearnedAmount && userdetails.totalearnedAmount>0? (parseFloat(userdetails.totalearnedAmount)/10000000).toFixed(5):0}</h2>
                              <h4>Total Earned BNB</h4>
                            </div>
                            <div className=""><img src={require("../assets/images/dash_icon_02.png")} className="img-fluid" alt="Copy" /></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="primary_box dash_box">
                        <div className="dashbox_middle_top">
                          <div>
                            <div>
                              <h2>{userdetails && userdetails.directreferral && userdetails.directreferral.length>0 ?userdetails.directreferral.length : 0 }</h2>
                              <h4>Direct Referral</h4>
                            </div>
                            <div className="">
                              <img src={require("../assets/images/dash_icon_03.png")} className="img-fluid" alt="Copy" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="primary_box dash_box">
                        <div className="dashbox_middle_top">
                          <div>
                            <div>
                              <h2>{weekcounta  ? parseInt(25) - parseInt(weekcounta) : 25 }</h2>
                              <h4>Global Royalty 1 Remaining Weeks</h4>
                            </div>
                            <div className="">
                              <img src={require("../assets/images/dash_icon_05.png")} className="img-fluid" alt="Copy" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="primary_box dash_box">
                        <div className="dashbox_middle_top">
                          <div>
                            <div>
                              <h2>{weekcountb ? parseInt(25) - parseInt(weekcountb) : 25 }</h2>
                              <h4>Global Royalty 2 Remaining Weeks</h4>
                            </div>
                            <div className="">
                              <img src={require("../assets/images/dash_icon_05.png")} className="img-fluid" alt="Copy" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>

                <div className="col-lg-9">
                  <div className="primary_box dash_box spacepad">
                    <div className="dashbox_middle_bottom slider">
                   <h2>Your Income</h2>
                   <Slider {...buy_item_carousel}>
                    <div>
                   <div className="dashbox_middle_bottom slidebox">
                   {/* <img src={require("../assets/images/logoicon.png")} className="copy_icon" alt="Copy" /> */}
                          <h5>Royalty Income</h5>
                          <h2 className="mb-0">{userdetails && userdetails.royalityincome && userdetails.royalityincome>0 ? (parseFloat(userdetails.royalityincome)/10000000).toFixed(5):0} <span> BNB </span></h2>
                        </div>
                        </div>
                        <div>
                   <div className="dashbox_middle_bottom slidebox">
                   {/* <img src={require("../assets/images/logoicon.png")} className="copy_icon" alt="Copy" /> */}
                          <h5>Global Pool Royalty 1 Income</h5>
                          <h2 className="mb-0">{userdetails && userdetails.globalroyality1income && userdetails.globalroyality1income>0 ? (parseFloat(userdetails.globalroyality1income)/10000000).toFixed(5):0}<span> BNB </span></h2>
                        </div>
                        </div>
                        <div>
                   <div className="dashbox_middle_bottom slidebox">
                   {/* <img src={require("../assets/images/logoicon.png")} className="copy_icon" alt="Copy" /> */}
                          <h5>Global Pool Royalty 2 Income</h5>
                          <h2 className="mb-0">{userdetails && userdetails.globalroyality2income && userdetails.globalroyality2income>0 ? (parseFloat(userdetails.globalroyality2income)/10000000).toFixed(5):0} <span> BNB </span></h2>
                        </div>
                        </div>
                        <div>
                         <div className="dashbox_middle_bottom slidebox">
                   {/* <img src={require("../assets/images/logoicon.png")} className="copy_icon" alt="Copy" /> */}
                          <h5>Total Earnings</h5>
                          <h2 className="mb-0">{userdetails && userdetails.totalearnedAmount && userdetails.totalearnedAmount>0 ? (parseFloat(userdetails.totalearnedAmount)/10000000).toFixed(5):0} <span> BNB </span></h2>
                        </div>
                    </div>
                    </Slider>
                   <div className="box">
                   <div className="row">
                   <div className="col-lg-4">
                        <div className="dashbox_middle_bottom">
                          <h5>Your Wallet Balance</h5>
                          <h2 className="mb-0">{userdata && userdata.bnbvalue ?(parseFloat(userdata.bnbvalue)/1e18).toFixed(3):0} <span> BNB </span> </h2>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="dashbox_middle_bottom">
                          <h5>Your Earned Amount</h5>
                          <h2 className="mb-0">{userdetails && userdetails.earnedAmount && userdetails.earnedAmount>0 ? (parseFloat(userdetails.earnedAmount)/10000000).toFixed(5):0} <span>BNB </span> </h2>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="dashbox_middle_bottom">
                        {withdrawprogress == "true" ?
                          <button className="primary_btn" type="button" >Processing..</button>
                        :
                          <button className="primary_btn" type="button" onClick={()=>claimRewards()}>WITHDRAW</button>
                        }
                        </div>
                    </div>                  
                   </div>
                   </div>
                  </div>
                  </div>
                  <div className="primary_box dash_box mt-4">
                    <div className="dashbox_middle_bottom">
                      <h2>Matrix Auto Pool Income</h2>
                      <div className="plan_wrapper">

                        <div className="buy_item">
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <div>
                                <h2>0.1</h2>
                                <h3>BNB</h3>
                              </div>
                              <p className="mb-0">Level <br></br> 1</p>
                            </div>
                            {/*<div>
                              <h4>Eligible Income</h4>
                              <h5>0.35 BNB</h5>
                            </div>*/}
                          </div>
                        </div>

                        <div className={userdetails && userdetails.currlevel && parseInt(userdetails.currlevel)+1 == 2 ? "buy_item":
                        userdetails && userdetails.currlevel<2 ? "buy_item buy_item_disabled" :"buy_item" }>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <div>
                                <h2>0.3</h2>
                                <h3>BNB</h3>
                              </div>
                              <p className="mb-0">Level <br></br> 2</p>
                            </div>
                            <div>
                              {userdetails && userdetails.currlevel<2 && userdetails.l2_missedearnings > 0 && <h4>Missed Earnings</h4> }
                              {userdetails && userdetails.currlevel<2 && userdetails.l2_missedearnings && userdetails.l2_missedearnings > 0 && <h5>{(parseFloat(userdetails.l2_missedearnings)/10000000).toFixed(3)} BNB</h5>}
                              {userdetails && userdetails.currlevel<2 && <h4>Upgrade Earnings</h4> }
                              {userdetails && userdetails.currlevel<2 && parseFloat(upgradeearningamount)>0 ? <h5>{(parseFloat(upgradeearningamount)/10000000).toFixed(3)} BNB</h5>:userdetails && userdetails.currlevel<2 && <h5>0 BNB</h5>}
                              {userdetails && userdetails.currlevel<2 && <h4>Upgrade Remaining</h4> }
                              {userdetails && userdetails.currlevel<2 && parseFloat(remainingamount)>0 ? <h5>{(parseFloat(remainingamount)/10000000).toFixed(3)} BNB</h5>:userdetails && userdetails.currlevel<2 && <h5>0 BNB</h5>}
                              {userdetails && userdetails.currlevel<2 && parseInt(userdetails.currlevel)+1 == 2 ?
                                <button type="button" className="primary_btn" onClick={()=>upgradeMannual()}>{parseInt(userdetails.currlevel)+1 == 2 && buyprocessing=="true" ? "Processing..":"Upgrade"}</button>
                                :userdetails && userdetails.currlevel<2 &&
                                <button type="button" className="primary_btn" >Upgrade</button>
                              }
                            </div>
                          </div>
                        </div>
                        <div className={userdetails && userdetails.currlevel<3 && parseInt(userdetails.currlevel)+1 == 3 ? "buy_item":
                        userdetails && userdetails.currlevel < 3 ? "buy_item buy_item_disabled" :"buy_item" }>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <div>
                                <h2>0.9</h2>
                                <h3>BNB</h3>
                              </div>
                              <p className="mb-0">Level <br></br> 3</p>
                            </div>
                            <div>
                              {userdetails && userdetails.currlevel<3 && <h4>Missed Earnings</h4> }
                              {userdetails && userdetails.currlevel<3 && userdetails.l3_missedearnings && userdetails.l3_missedearnings > 0 ? <h5>{(parseFloat(userdetails.l3_missedearnings)/10000000).toFixed(3)} BNB</h5>:userdetails && userdetails.currlevel<3 && <h5>0 BNB</h5>}
                              {userdetails && userdetails.currlevel<3 && parseInt(userdetails.currlevel)+1 == 3 ?
                                <button type="button" className="primary_btn" onClick={()=>buynow(3)}>{parseInt(userdetails.currlevel)+1 == 3 && buyprocessing=="true" ? "Processing..":"Buy Now"}</button>
                                :userdetails && userdetails.currlevel<3 &&
                                <button type="button" className="primary_btn" >Buy Now</button>
                              }
                            </div>
                          </div>
                        </div>

                        <div className={userdetails && userdetails.currlevel<4 && parseInt(userdetails.currlevel)+1 == 4 ? "buy_item":
                        userdetails && userdetails.currlevel<4 ? "buy_item buy_item_disabled" :"buy_item"}>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <div>
                                <h2>2.7</h2>
                                <h3>BNB</h3>
                              </div>
                              <p className="mb-0">Level <br></br> 4</p>
                            </div>
                            <div>
                              {userdetails && userdetails.currlevel<4 && <h4>Missed Earnings</h4> }
                              {userdetails && userdetails.currlevel<4 && userdetails.l4_missedearnings && userdetails.l4_missedearnings > 0 ? <h5>{(parseFloat(userdetails.l4_missedearnings)/10000000).toFixed(3)} BNB</h5>:userdetails && userdetails.currlevel<4 && <h5>0 BNB</h5>}
                              {userdetails && userdetails.currlevel<4 && parseInt(userdetails.currlevel)+1 == 4 ?
                                <button type="button" className="primary_btn" onClick={()=>buynow(4)}>{parseInt(userdetails.currlevel)+1 == 4 && buyprocessing=="true" ? "Processing..":"Buy Now"}</button>
                                :userdetails && userdetails.currlevel<4 &&
                                <button type="button" className="primary_btn" >Buy Now</button>
                              }
                            </div>
                          </div>
                        </div>

                        <div className={userdetails && userdetails.currlevel<5 && parseInt(userdetails.currlevel)+1 == 5 ? "buy_item":
                        userdetails && userdetails.currlevel<5 ? "buy_item buy_item_disabled" :"buy_item"}>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <div>
                                <h2>8.1</h2>
                                <div></div>
                                <h3>BNB</h3>
                              </div>
                              <p className="mb-0">Level <br></br> 5</p>
                            </div>
                            <div>
                              {userdetails && userdetails.currlevel<5 && <h4>Missed Earnings</h4> }
                              {userdetails && userdetails.currlevel<5 && userdetails.l5_missedearnings && userdetails.l5_missedearnings > 0 ? <h5>{(parseFloat(userdetails.l5_missedearnings)/10000000).toFixed(3)} BNB</h5>:userdetails && userdetails.currlevel<5 && <h5>0 BNB</h5>}
                              {userdetails && userdetails.currlevel<5 && parseInt(userdetails.currlevel)+1 == 5 ?
                                <button type="button" className="primary_btn" onClick={()=>buynow(5)}>{parseInt(userdetails.currlevel)+1 == 5 && buyprocessing=="true" ? "Processing..":"Buy Now"}</button>
                                :userdetails && userdetails.currlevel<5 &&
                                <button type="button" className="primary_btn" >Buy Now</button>
                              }
                            </div>
                          </div>
                        </div>

                        <div className={userdetails && userdetails.currlevel<6 && parseInt(userdetails.currlevel)+1 == 6 ? "buy_item":
                        userdetails && userdetails.currlevel<6 ? "buy_item buy_item_disabled" :"buy_item"}>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <div>
                                <h2>24.3</h2>
                                <div></div>
                                <h3>BNB</h3>
                              </div>
                              <p className="mb-0">Level<br></br>  6</p>
                            </div>
                            <div>
                              {userdetails && userdetails.currlevel<6 && <h4>Missed Earnings</h4> }
                              {userdetails && userdetails.currlevel<6 && userdetails.l6_missedearnings && userdetails.l6_missedearnings > 0 ? <h5>{(parseFloat(userdetails.l6_missedearnings)/10000000).toFixed(3)} BNB</h5>:userdetails && userdetails.currlevel<6 && <h5>0 BNB</h5>}
                              {userdetails && userdetails.currlevel<6 && parseInt(userdetails.currlevel)+1 == 6 ?
                                <button type="button" className="primary_btn" onClick={()=>buynow(6)}>{parseInt(userdetails.currlevel)+1 == 6 && buyprocessing=="true" ? "Processing..":"Buy Now"}</button>
                                :userdetails && userdetails.currlevel<6 &&
                                <button type="button" className="primary_btn" >Buy Now</button>
                              }
                            </div>
                          </div>
                        </div>

                        <div className={userdetails && userdetails.currlevel<7 && parseInt(userdetails.currlevel)+1 == 7 ? "buy_item":
                        userdetails && userdetails.currlevel<7 ? "buy_item buy_item_disabled" :"buy_item"}>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <div>
                                <h2>72.9</h2>
                                <h3>BNB</h3>
                              </div>
                              <p className="mb-0">Level <br></br> 7</p>
                            </div>
                            <div>
                              {userdetails && userdetails.currlevel<7 && <h4>Missed Earnings</h4> }
                              {userdetails && userdetails.currlevel<7 && userdetails.l7_missedearnings && userdetails.l7_missedearnings > 0 ? <h5>{(parseFloat(userdetails.l7_missedearnings)/10000000).toFixed(3)} BNB</h5>:userdetails && userdetails.currlevel<7 && <h5>0 BNB</h5>}
                              {userdetails && userdetails.currlevel<7 && parseInt(userdetails.currlevel)+1 == 7 ?
                                <button type="button" className="primary_btn" onClick={()=>buynow(7)}>{parseInt(userdetails.currlevel)+1 == 7 && buyprocessing=="true" ? "Processing..":"Buy Now"}</button>
                                :userdetails && userdetails.currlevel<7 &&
                                <button type="button" className="primary_btn" >Buy Now</button>
                              }
                            </div>
                          </div>
                        </div>

                        <div className={userdetails && userdetails.currlevel<8 && parseInt(userdetails.currlevel)+1 == 8 ? "buy_item":
                        userdetails && userdetails.currlevel<8 ? "buy_item buy_item_disabled" :"buy_item"}>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <div>
                                <h2>218.7</h2>
                                <h3>BNB</h3>
                              </div>
                              <p className="mb-0">Level <br></br> 8</p>
                            </div>
                            <div>
                              {userdetails && userdetails.currlevel<8 && <h4>Missed Earnings</h4> }
                              {userdetails && userdetails.currlevel<8 && userdetails.l8_missedearnings && userdetails.l8_missedearnings > 0 ? <h5>{(parseFloat(userdetails.l8_missedearnings)/10000000).toFixed(3)} BNB</h5>:userdetails && userdetails.currlevel<8 && <h5>0 BNB</h5>}
                              {userdetails && userdetails.currlevel<8 && parseInt(userdetails.currlevel)+1 == 8 ?
                                <button type="button" className="primary_btn" onClick={()=>buynow(8)}>{parseInt(userdetails.currlevel)+1 == 8 && buyprocessing=="true" ? "Processing..":"Buy Now"}</button>
                                : userdetails && userdetails.currlevel<8 &&
                                <button type="button" className="primary_btn" >Buy Now</button>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="primary_box dash_box tree mt-4">
                  <div class="accordion" id="accordionExample">
                    <div class="wholebox">
                      <div class="boxctn">
                        <h5 class="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#box5" aria-expanded="false" aria-controls="box">
                          Genealogy Tree
                        </h5>
                        <div id="box5" class="collapse" data-bs-parent="#accordionExample">
                          <div className="dashbox_middle_bottom">
                          {!treeprogress?
                            <div>
                              <AnimatedTree data={selected}
                                height={1200}
                                width={800}
                                gProps={{ className: "node", onClick: handleClick}}
                                nodeShape="image"
                                nodeProps={{ href: config.Front_URL+"Images/tree_icon.png", width: "13", height: "21", }}
                                margins={{ top: 50, bottom: 50, left: 80, right: 80 }}
                                textProps={{ dx: -16, dy: 25 }}
                              />
                            </div>
                            :
                            <div>
                            <center><p className="text-danger mb-1">Processing....,Please Wait</p></center>
                            </div>
                          }
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>

                  <div className="primary_box dash_box mt-4">
                    <div className="dashbox_middle_bottom">
                      <h2>Your Network</h2>
                      <div className='plan_income_table_div'>
                        <div className='table-responsive'>
                          <table className='table plan_income_table mt-0'>
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>ID</th>
                                <th>Address</th>
                                <th>Level</th>
                              </tr>
                            </thead>
                            <tbody>
                            {mynetwork && mynetwork.length>0 && mynetwork.map((item,index)=>{
                              return(
                                <tr>
                                  <td>{parseInt(index)+1}</td>
                                  <td>{item.userid}</td>
                                  <td>{item.address}</td>
                                  <td>{item.level}</td>
                                </tr>
                              )
                            })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="primary_box dash_box mt-4">
                    <div className="dashbox_middle_bottom">
                      <h2>Your Level Income</h2>
                      <div className='plan_income_table_div'>
                        <div className='table-responsive'>
                          <table className='table plan_income_table mt-0'>
                            <thead>
                              <tr>
                                <th>Level</th>
                                <th>No of Users</th>
                                <th>Income (BNB)</th>
                              </tr>                                
                            </thead>
                            {!levelload ? 
                              <tbody>
                                  <tr>
                                    <td>L1</td>
                                    <td>{levelcounts && levelcounts.l1count ? levelcounts.l1count : 0 }</td>
                                    <td>{levelcounts && levelcounts.l1count ? (parseFloat(levelcounts.l1count)*0.003).toFixed(5) : 0 }</td>
                                  </tr>
                                  <tr>
                                    <td>L2</td>
                                    <td>{levelcounts && levelcounts.l2count ? levelcounts.l2count : 0 }</td>
                                    <td>{levelcounts && levelcounts.l2count ? (parseFloat(levelcounts.l2count)*0.003).toFixed(5) : 0 }</td>
                                  </tr>
                                  <tr>
                                    <td>L3</td>
                                    <td>{levelcounts && levelcounts.l3count ? levelcounts.l3count : 0 }</td>
                                    <td>{levelcounts && levelcounts.l3count ? (parseFloat(levelcounts.l3count)*0.003).toFixed(5) : 0 }</td>
                                  </tr>
                                  <tr>
                                    <td>L4</td>
                                    <td>{levelcounts && levelcounts.l4count ? levelcounts.l4count : 0 }</td>
                                    <td>{levelcounts && levelcounts.l4count ? (parseFloat(levelcounts.l4count)*0.003).toFixed(5) : 0 }</td>
                                  </tr>
                                  <tr>
                                    <td>L5</td>
                                    <td>{levelcounts && levelcounts.l5count ? levelcounts.l5count : 0 }</td>
                                    <td>{levelcounts && levelcounts.l5count ? (parseFloat(levelcounts.l5count)*0.003).toFixed(5) : 0 }</td>
                                  </tr>
                                  <tr>
                                    <td>L6</td>
                                    <td>{levelcounts && levelcounts.l6count ? levelcounts.l6count : 0 }</td>
                                    <td>{levelcounts && levelcounts.l6count ? (parseFloat(levelcounts.l6count)*0.003).toFixed(5) : 0 }</td>
                                  </tr>
                                  <tr>
                                    <td>L7</td>
                                    <td>{levelcounts && levelcounts.l7count ? levelcounts.l7count : 0 }</td>
                                    <td>{levelcounts && levelcounts.l7count ? (parseFloat(levelcounts.l7count)*0.003).toFixed(5) : 0 }</td>
                                  </tr>
                                  <tr>
                                    <td>L8</td>
                                    <td>{levelcounts && levelcounts.l8count ? levelcounts.l8count : 0 }</td>
                                    <td>{levelcounts && levelcounts.l8count ? (parseFloat(levelcounts.l8count)*0.003).toFixed(5) : 0 }</td>
                                  </tr>
                                  <tr>
                                    <td>L9</td>
                                    <td>{levelcounts && levelcounts.l9count ? levelcounts.l9count : 0 }</td>
                                    <td>{levelcounts && levelcounts.l9count ? (parseFloat(levelcounts.l9count)*0.003).toFixed(5) : 0 }</td>
                                  </tr>
                                  <tr>
                                    <td>L10</td>
                                    <td>{levelcounts && levelcounts.l10count ? levelcounts.l10count : 0 }</td>
                                    <td>{levelcounts && levelcounts.l10count ? (parseFloat(levelcounts.l10count)*0.003).toFixed(5) : 0 }</td>
                                  </tr>
                                  
                                  <tr>     
                                    <td colspan={3}>
                                      <h4 className="text-center">{"Total Earned BNB : "+
                                      ((levelcounts && levelcounts.l1count ? (parseFloat(levelcounts.l1count)*0.003): 0) + 
                                      (levelcounts && levelcounts.l2count ? (parseFloat(levelcounts.l2count)*0.003): 0) + 
                                      (levelcounts && levelcounts.l3count ? (parseFloat(levelcounts.l3count)*0.003): 0) + 
                                      (levelcounts && levelcounts.l4count ? (parseFloat(levelcounts.l4count)*0.003): 0) + 
                                      (levelcounts && levelcounts.l5count ? (parseFloat(levelcounts.l5count)*0.003): 0) + 
                                      (levelcounts && levelcounts.l6count ? (parseFloat(levelcounts.l6count)*0.003): 0) + 
                                      (levelcounts && levelcounts.l7count ? (parseFloat(levelcounts.l7count)*0.003): 0) + 
                                      (levelcounts && levelcounts.l8count ? (parseFloat(levelcounts.l8count)*0.003): 0) + 
                                      (levelcounts && levelcounts.l9count ? (parseFloat(levelcounts.l9count)*0.003): 0) + 
                                      (levelcounts && levelcounts.l10count ? (parseFloat(levelcounts.l10count)*0.003): 0)).toFixed(5)
                                      } </h4>
                                    </td>
                                  </tr>
                              </tbody>
                              :
                              ""
                            }
                          </table>
                        </div>
                      </div>
                    </div>
                </div>
                <div className="primary_box dash_box mt-4">
                    <div className="dashbox_middle_bottom">
                      <h2>Matrix Upgrade Income </h2>
                      <div className='plan_income_table_div'>
                        <div className='table-responsive'>
                          <table className='table plan_income_table mt-0'>
                            <thead>
                              <tr>
                                <th>Level</th>
                                <th>Upgraded User</th>
                                <th>Waiting for Upgrade</th>
                                <th>Missed Earnings</th>
                              </tr>
                            </thead>
                            {!planprogress && 
                            <tbody>
                            {/*  <tr>
                                <td>L1</td>
                                <td>{planrange && planrange.l1range && planrange.l1range.greator ? planrange.l1range.greator : 0}</td>
                                <td>{planrange && planrange.l1range && planrange.l1range.smaller ? planrange.l1range.smaller : 0}</td>
                                <td>{planrange && planrange.l1missed ? parseFloat(planrange.l1missed).toFixed(5) : 0 }</td>
                              </tr> */}
                              <tr>
                                <td>L2</td>
                                <td>{planrange && planrange.l2range && planrange.l2range.greator ? planrange.l2range.greator : 0}</td>
                                <td>{planrange && planrange.l2range && planrange.l2range.smaller ? planrange.l2range.smaller : 0}</td>
                                <td>{planrange && planrange.l2missed ? parseFloat(planrange.l2missed).toFixed(5) : 0 }</td>
                              </tr>
                              <tr>
                                <td>L3</td>
                                <td>{planrange && planrange.l3range && planrange.l3range.greator ? planrange.l3range.greator : 0}</td>
                                <td>{planrange && planrange.l3range && planrange.l3range.smaller ? planrange.l3range.smaller : 0}</td>
                                <td>{planrange && planrange.l3missed ? parseFloat(planrange.l3missed).toFixed(5) : 0 }</td>
                              </tr>
                              <tr>
                                <td>L4</td>
                                <td>{planrange && planrange.l4range && planrange.l4range.greator ? planrange.l4range.greator : 0}</td>
                                <td>{planrange && planrange.l4range && planrange.l4range.smaller ? planrange.l4range.smaller : 0}</td>
                                <td>{planrange && planrange.l4missed ? parseFloat(planrange.l4missed).toFixed(5) : 0 }</td>
                              </tr>
                              <tr>
                                <td>L1</td>
                                <td>{planrange && planrange.l5range && planrange.l5range.greator ? planrange.l5range.greator : 0}</td>
                                <td>{planrange && planrange.l5range && planrange.l5range.smaller ? planrange.l5range.smaller : 0}</td>
                                <td>{planrange && planrange.l5missed ? parseFloat(planrange.l5missed).toFixed(5) : 0 }</td>
                              </tr>
                              <tr>
                                <td>L2</td>
                                <td>{planrange && planrange.l6range && planrange.l6range.greator ? planrange.l6range.greator : 0}</td>
                                <td>{planrange && planrange.l6range && planrange.l6range.smaller ? planrange.l6range.smaller : 0}</td>
                                <td>{planrange && planrange.l6missed ? parseFloat(planrange.l6missed).toFixed(5) : 0 }</td>
                              </tr>
                              <tr>
                                <td>L3</td>
                                <td>{planrange && planrange.l7range && planrange.l7range.greator ? planrange.l7range.greator : 0}</td>
                                <td>{planrange && planrange.l7range && planrange.l7range.smaller ? planrange.l7range.smaller : 0}</td>
                                <td>{planrange && planrange.l7missed ? parseFloat(planrange.l7missed).toFixed(5) : 0 }</td>
                              </tr>
                              <tr>
                                <td>L4</td>
                                <td>{planrange && planrange.l8range && planrange.l8range.greator ? planrange.l8range.greator : 0}</td>
                                <td>{planrange && planrange.l8range && planrange.l8range.smaller ? planrange.l8range.smaller : 0}</td>
                                <td>{planrange && planrange.l8missed ? parseFloat(planrange.l8missed).toFixed(5) : 0 }</td>
                              </tr>
                            </tbody>
                          }
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
