import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer.js";
import NavbarAdmin from "../components/NavbarAdmin.js";
import { AnimatedTree } from "react-tree-graph";
import "react-tree-graph/dist/style.css";
import Web3 from "web3";
import { getCurAddr, getrecords, get_emergencydistributedetails ,get_distributedetails, updatedistribute, canceldistribute } from "../action/Apicontroller";
import { toastAlert } from "../helper/toastAlert";
import binanceapi from "../ABI/binancepayABI";
import config from '../config/config';
import moment from 'moment';
import Countdown, { zeroPad } from "react-countdown";
import { type WalletClient, useWalletClient } from 'wagmi';
import { useSelector, useDispatch } from "react-redux";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Setting(props) {
  var { id } = useParams();
  const dispatch = useDispatch()
  let chainid = config.networkVersion ;
  const { data: walletClient } = useWalletClient({ chainid })
  const [progress, setprogress] = useState("false");
  const [autoprogress, setautoprogress] = useState("false");
  const [distributestatus, setdistributestatus] = useState("");
  const [withdrawprogress, setwithdrawprogress] = useState("false");
  const [countprogress, setcountprogress] = useState("false");
  const [inputaddress, setinputaddress] = useState("");
  const [amount, setamount] = useState("");
  const [count, setcount] = useState(0);
  const [totaluser,settotaluser] =useState(0);
  const [records, setrecords]=useState([]);
  const [lastrewardtime,setlastrewardtime] = useState("");
  const [emer_progress,setemer_progress] = useState("false");

  useEffect(() => {
    getdetails();
    getrec();
  }, [walletClient]);

  async function getrec() {
    let datas = await getrecords();
    if (datas && datas.data && datas.data.data) {
      setrecords(datas.data.data)
      let usercount =  (datas.data.data).filter(item => item.royality>0 || item.globalroyality_A>0 || item.globalroyality_B>0 ).length
      settotaluser(usercount);
    }
  }

  async function getdetails() {
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      var binanceContract = new web3.eth.Contract(binanceapi, config.Binancepaycontract);
      let lasttimestamp = await binanceContract.methods.lastRewardTimestamp().call();
      lasttimestamp = moment.unix(lasttimestamp).toDate();
      lasttimestamp = moment(lasttimestamp).add(7, 'days');
      setlastrewardtime(lasttimestamp);
      let distribute_status = await binanceContract.methods.isAutodistribute().call();
      setdistributestatus(distribute_status);
    }
  }

  async function empty() {

  }

  const addresschange = (e) => {
    setinputaddress(e.target.value);
  }

  const amountchange = (e) => {
    setamount(e.target.value);
  }

  const countchange = (e) => {
    setcount(e.target.value);
  }


  async function submitautodistribute() {
    try {
      setautoprogress("true")
      let datas = await getCurAddr();
      if (datas && datas.address &&
        datas.address != undefined &&
        datas.address != null &&
        datas.address != "") {
        let web3 = new Web3(datas && datas.provider && datas.provider != null && datas.provider != undefined && datas.provider != "" ? datas.provider : window.ethereum);
        var binanceContract = new web3.eth.Contract(binanceapi, config.Binancepaycontract);
        let owneraddress = await binanceContract.methods.owner().call();
        if ((owneraddress).toLowerCase() == (datas.address).toLowerCase()) {
          let register = await binanceContract.methods.setAutoDistribute(!distributestatus).send({
            from: datas.address
          })
          if (register && register.status && register.transactionHash) {
            setautoprogress("false")
            toastAlert('success', "Updated Successfully", 'network');
            window.location.href = "/dashboard"
          } else {
            setautoprogress("false")
            toastAlert('success', "Failed , try again later", 'network');
          }
        } else {
          setautoprogress("false")
          toastAlert('error', "Login into Admin Address", 'network');
        }

      } else {
        setautoprogress("false")
        toastAlert('error', "Please select BSC chain network", 'network');
      }
    } catch (err) {
      setautoprogress("false")
    }
  }

  async function emergency(){
    setemer_progress("true");
    await get_emergencydistributedetails();
    await getrec();
    setemer_progress("false");
  }

  async function submit(num) {
    try {
      setprogress("true")
      let datas = await getCurAddr();
      if (datas && datas.address &&
        datas.address != undefined &&
        datas.address != null &&
        datas.address != "") {
        let web3 = new Web3(datas && datas.provider && datas.provider != null && datas.provider != undefined && datas.provider != "" ? datas.provider : window.ethereum);
        var binanceContract = new web3.eth.Contract(binanceapi, config.Binancepaycontract);
        let owneraddress = await binanceContract.methods.owner().call();
        //if ((owneraddress).toLowerCase() == (datas.address).toLowerCase()) {
          let datass = {
            "skip": num == 1 ? 0 : num == 2 ? 500 : num ==3 ? 1000 :  num ==4 ? 1500 : 2000,
            "limit": num == 1 ? 500 : num == 2 ? 1000 : num ==3 ? 1500 :  num ==4 ? 2000 : 2500
          }
          let distribut_details = await get_distributedetails(datass);
          if(distribut_details && distribut_details.data && distribut_details.data.status){
            console.log(distribut_details.data.length,"1", distribut_details.data.address,"2", distribut_details.data.ramount,"3", distribut_details.data.g1amaount,"4", distribut_details.data.g2bamount,"=5=======")
          let register = await binanceContract.methods.distributeToRoyal(distribut_details.data.length, distribut_details.data.address, distribut_details.data.ramount, distribut_details.data.g1amaount, distribut_details.data.g2bamount).send({
            from: datas.address
          })
          if (register && register.status && register.transactionHash) {
            //await updatedistribute();
            setprogress("false")
            toastAlert('success', "Distributed Successfully", 'network');
            window.location.reload();
          } else {
            await canceldistribute();
            setprogress("false")
            toastAlert('success', "Failed , try again later", 'network');
          }


          // let register = await binanceContract.methods.distributeByAdmin().send({
          //   from: datas.address
          // })
          // if (register && register.status && register.transactionHash) {
          //   setprogress("false")
          //   toastAlert('success', "Distributed Successfully", 'network');
          //   window.location.href = "/dashboard"
          // } else {
          //   setprogress("false")
          //   toastAlert('success', "Failed , try again later", 'network');
          // }
       

      }else{
        setprogress("false")
        toastAlert('error', distribut_details.data.message, 'network');
      }
       // } else {
       //    setprogress("false")
       //    toastAlert('error', "Login into Admin Address", 'network');
       //  }

      } else {
        setprogress("false")
        toastAlert('error', "Please select BSC chain network", 'network');
      }
    } catch (err) {
      console.log(err,"=====err")
      await canceldistribute();
      setprogress("false")
    }
  }

  async function withdraw() {
    try {
      setwithdrawprogress("true")
      let datas = await getCurAddr();
      if (datas && datas.address &&
        datas.address != undefined &&
        datas.address != null &&
        datas.address != "") {
        if (parseFloat(amount) > 0) {
          let web3 = new Web3(datas && datas.provider && datas.provider != null && datas.provider != undefined && datas.provider != "" ? datas.provider : window.ethereum);
          if (web3.utils.isAddress(inputaddress)) {
            var binanceContract = new web3.eth.Contract(binanceapi, config.Binancepaycontract);
            let owneraddress = await binanceContract.methods.owner().call();
            if ((owneraddress).toLowerCase() == (datas.address).toLowerCase()) {
              let withamt = parseFloat(amount) * 1e18
              let sendamt = await convert(withamt);
              let register = await binanceContract.methods.safeWithDrawbnb(sendamt.toString(), inputaddress).send({
                from: datas.address
              })
              if (register && register.status && register.transactionHash) {
                setwithdrawprogress("false")
                toastAlert('success', "Withdraw doned Successfully", 'network');
                window.location.href = "/dashboard"
              } else {
                setwithdrawprogress("false")
                toastAlert('success', "Failed , try again later", 'network');
              }
            } else {
              setwithdrawprogress("false")
              toastAlert('error', "Login into Admin Address", 'network');
            }
          } else {
            setwithdrawprogress("false")
            toastAlert('error', "Enter Valid Address", 'network');
          }
        } else {
          setwithdrawprogress("false")
          toastAlert('error', "Enter Valid Amount", 'network');
        }
      } else {
        setwithdrawprogress("false")
        toastAlert('error', "Please select BSC chain network", 'network');
      }
    } catch (err) {
      setwithdrawprogress("false")
    }
  }

  async function distributecount() {
    try {
      setcountprogress("true")
      let datas = await getCurAddr();
      if (datas && datas.address &&
        datas.address != undefined &&
        datas.address != null &&
        datas.address != "") {
        if (parseFloat(count) > 0) {
          let web3 = new Web3(datas && datas.provider && datas.provider != null && datas.provider != undefined && datas.provider != "" ? datas.provider : window.ethereum);
          var binanceContract = new web3.eth.Contract(binanceapi, config.Binancepaycontract);
          let owneraddress = await binanceContract.methods.owner().call();
          if ((owneraddress).toLowerCase() == (datas.address).toLowerCase()) {
            let register = await binanceContract.methods.updateDistributeCount(count).send({
              from: datas.address
            })
            if (register && register.status && register.transactionHash) {
              setcountprogress("false")
              toastAlert('success', "Successfully Done", 'network');
              window.location.href = "/dashboard"
            } else {
              setcountprogress("false")
              toastAlert('success', "Failed , try again later", 'network');
            }
          } else {
            setcountprogress("false")
            toastAlert('error', "Login into Admin Address", 'network');
          }
        } else {
          setcountprogress("false")
          toastAlert('error', "Enter Valid Count", 'network');
        }
      } else {
        setcountprogress("false")
        toastAlert('error', "Please select BSC chain network", 'network');
      }
    } catch (err) {
      setcountprogress("false")
    }
  }


  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n.toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
      : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
  }

  const currentDate = new Date();
  const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();
  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="timer_panel">
        <span><span className="timer_time">{zeroPad(days)}</span><span className="timer_label">Days</span></span>
        <span className="timer_dots">:</span>
        <span><span className="timer_time">{zeroPad(hours)}</span><span className="timer_label">Hours</span></span>
        <span className="timer_dots">:</span>
        <span><span className="timer_time">{zeroPad(minutes)}</span><span className="timer_label">Mins</span></span>
        <span className="timer_dots">:</span>
        <span><span className="timer_time">{zeroPad(seconds)}</span><span className="timer_label">Secs</span></span>
      </div>
    );
  };

  return (
    <div>
      <ScrollToTopOnMount />
      <NavbarAdmin />
      <div className="inner_wrapper">
        <div className="container">
          <div className="dashboard_panel join_now_panel">
            <div className="row">
              <div className="col-md-12 col-lg-8 m-auto">
                <div className="title_flex">
                  <h2 className="inner_title">Settings</h2>
                  <div className="row">
                    {/* <div className="col-md-6">
                      <div className="primary_box dash_box mt-4">
                        <div className="form-group row">
                          <div className="col-lg-12 text-start">
                            <label for="exampleFormControlInput1" class="form-label">Auto Distribute</label>
                            <button type="button" className="primary_btn" onClick={autoprogress == "false" ? () => submitautodistribute() : () => empty()} disabled={(progress == "true" || autoprogress == "true" || withdrawprogress == "true" || countprogress == "true") ? "disabled" : ""}>{autoprogress == "false" && distributestatus ? "Disable" : autoprogress == "false" && distributestatus == false ? "Enable" : "Processing..Please Wait.."}</button>
                          </div>
                        </div>
                      </div>
                    </div>*/}
                    <div className="col-md-6">
                      <div className="primary_box dash_box mt-4">
                        <div className="form-group row">
                          <div className="col-lg-12 text-start">
                            <label for="exampleFormControlInput1" class="form-label">Distribute Team and Global Royality</label>
                            <label for="exampleFormControlInput1" class="form-label">{"(Users Count:" + totaluser + " )"}</label>
                            <button type="button" className="primary_btn" onClick={progress == "false" ? () => submit(1) : () => empty()} disabled={(progress == "true" || autoprogress == "true" || withdrawprogress == "true" || countprogress == "true") ? "disabled" : ""}>{progress == "false" ? "Distribute 1" : "Processing..Please Wait.."}</button>
                            <button type="button" className="primary_btn" onClick={progress == "false" ? () => submit(2) : () => empty()} disabled={(progress == "true" || autoprogress == "true" || withdrawprogress == "true" || countprogress == "true") ? "disabled" : ""}>{progress == "false" ? "Distribute 2" : "Processing..Please Wait.."}</button>
                            <button type="button" className="primary_btn" onClick={progress == "false" ? () => submit(3) : () => empty()} disabled={(progress == "true" || autoprogress == "true" || withdrawprogress == "true" || countprogress == "true") ? "disabled" : ""}>{progress == "false" ? "Distribute 3" : "Processing..Please Wait.."}</button> 
                            <button type="button" className="primary_btn" onClick={progress == "false" ? () => submit(4) : () => empty()} disabled={(progress == "true" || autoprogress == "true" || withdrawprogress == "true" || countprogress == "true") ? "disabled" : ""}>{progress == "false" ? "Distribute 4" : "Processing..Please Wait.."}</button> 
                            <button type="button" className="primary_btn" onClick={progress == "false" ? () => submit(5) : () => empty()} disabled={(progress == "true" || autoprogress == "true" || withdrawprogress == "true" || countprogress == "true") ? "disabled" : ""}>{progress == "false" ? "Distribute 5" : "Processing..Please Wait.."}</button> 
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="primary_box dash_box mt-4">
                        <p className="text-danger mb-1">If any emergency, You can distribute Here.</p>
                        {emer_progress=="true" && 
                          <p className="text-danger mb-1">Wait for 20 Minutes</p>
                        }
                        {emer_progress=="true"?
                          <button type="button" className="primary_btn">Please Wait..</button>
                          :
                          <button type="button" className="primary_btn" onClick={()=>emergency()}>Emergency Distribute</button>
                        }
                        <div className="timer"> 
                        {lastrewardtime!="" &&                        
                          <Countdown date={new Date(lastrewardtime)} 
                                  autoStart={true}
                                  onStart={() => new Date(lastrewardtime)} renderer={renderer} />  
                        }                       
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="primary_box dash_box mt-4">
                        <div className="form-group row">
                          <div className="col-lg-12 text-start">
                            <label for="exampleFormControlInput1" class="form-label">Safe Withdraw</label>
                            <input type="text" value={amount} class="form-control mb-3 primary_inp" id="exampleFormControlInput11" placeholder="Enter Amount" onChange={amountchange} />
                            <input type="text" value={inputaddress} class="form-control primary_inp" id="exampleFormControlInput11" placeholder="Enter Address" onChange={addresschange} />
                            <button type="button" className="primary_btn" onClick={withdrawprogress == "false" ? () => withdraw() : () => empty()} disabled={(progress == "true" || autoprogress == "true" || withdrawprogress == "true" || countprogress == "true") ? "disabled" : ""}>{withdrawprogress == "false" ? "Withdraw" : "Processing..Please Wait.."}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-12">
                      <div className="primary_box dash_box mt-4">
                        <div className="form-group row">
                          <div className="col-lg-12 m-auto text-start">
                            <label for="exampleFormControlInput1" class="form-label">Distribute User Count</label>
                            <input type="text" value={count} class="form-control primary_inp" id="exampleFormControlInput11" placeholder="Enter Count" onChange={countchange} />
                            <button type="button" className="primary_btn" onClick={countprogress == "false" ? () => distributecount() : () => empty()} disabled={(progress == "true" || autoprogress == "true" || withdrawprogress == "true" || countprogress == "true") ? "disabled" : ""}>{countprogress == "false" ? "Distribute" : "Processing..Please Wait.."}</button>
                          </div>
                        </div>
                      </div>
                    </div>*/}
                  </div>
                  <div className="row">
                    <div className="primary_box dash_box mt-4">
                      <div className="dashbox_middle_bottom">
                        <h2>Distribution</h2>
                        <div className='plan_income_table_div'>
                          <div className='table-responsive'>
                            <table className='table plan_income_table mt-0'>
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>UserId</th>
                                  <th>Address</th>
                                  <th>Level</th>
                                  <th>Royality</th>
                                  <th>Global Royality A</th>
                                  <th>Global Royality B</th>
                                </tr>
                              </thead>
                              <tbody>
                                {records && records.length > 0 && records.map((item, index) => {
                                  return (
                                    <tr>
                                      <td>{parseInt(index) + 1}</td>
                                      <td>{item.userid}</td>
                                      <td>{item.address}</td>
                                      <td>{item.level}</td>
                                      <td>{(Math.round(item.royality)) / 10000000}</td>
                                      <td>{(Math.round(item.globalroyality_A)) / 10000000}</td>
                                      <td>{(Math.round(item.globalroyality_B)) / 10000000}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
