import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import $ from "jquery";
import config from "../config/config";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import { getCurAddr } from "../action/Apicontroller";
import { toastAlert } from "../helper/toastAlert";
import { type WalletClient, useWalletClient,useDisconnect } from 'wagmi'
import { useConnect } from 'wagmi';
import { useSelector, useDispatch } from "react-redux";
import browser from 'browser-detect';
import { setWallet } from "../reducers/Actions";

export default function Navbar(props) {
  const dispatch = useDispatch()
  const { connect, connectors, error, isLoading, pendingConnector } =useConnect();
  const { disconnect } = useDisconnect()
  let chainid = config.networkVersion ;
  const { data: walletClient } = useWalletClient({ chainid });
  const [selected, setSelected] = useState('GB');
  const [account, setaccount] = useState('');
  const [data, setdata] = useState({});
  const [status, setstatus] = useState(true);
  const timerRef = useRef(null);

  useEffect(() => {
    loadScript();
    checkConnect();
  }, [walletClient]);

  function checkConnect(){
    console.log("checkConnect")
    if(walletClient){
      const { account, chain, transport } = walletClient
        const network = {
            chainId: chain.id,
            name: chain.name,
            ensAddress: chain.contracts?.ensRegistry?.address,
        }
        dispatch(setWallet({
          connect : "yes",
          web3 : transport
        }));
        getdetails();

    }
  }

  function loadScript() {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 100) {
        $(".main_navbar").addClass("fixed_navbar");
      } else {
        $(".main_navbar").removeClass("fixed_navbar");
      }
    });
  }

  async function getdetails() {
    let datas = await getCurAddr();
    setstatus(true);
    if (datas && datas.address &&
      datas.address != undefined &&
      datas.address != null &&
      datas.address != "" && localStorage.getItem("account")) {
      setdata(datas);
    }
  }

  async function connectTrustWallet() {
    try {
      var web3 = new Web3(window.ethereum);
      const provider = new WalletConnectProvider({
        rpc: {
          56: "https://bsc-dataseed.binance.org/",
        },
        chainId: 56
      });

      provider.on("connect", () => {
      });
      await provider.enable();
      web3 = new Web3(provider);
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      if (result[0] != undefined) {
        if (config.networkVersion == network) {
          localStorage.setItem("wallettype", "trust")
          localStorage.setItem("account", result[0]);
          window.location.reload();
        } else {
          await provider.disconnect();
          toastAlert('error', "please select BSC chain network", 'network');
        }
      }
    } catch (err) {
      toastAlert('error', "Error Occured Please Try again", 'network');
      localStorage.clear();
      window.location.href="/";
    }
  }

  async function connectClick() {
    try {
      const web3 = new Web3(window.ethereum);
      const result = await web3.eth.getAccounts();
      web3.eth.net.getId().then(res => {
        if (result[0] != undefined) {
          if (res === config.networkVersion) {
            toastAlert('success', "Connected Wallet", 'excerciseerr');
            setaccount(result[0]);
            localStorage.setItem('account', result[0]);
            localStorage.setItem('wallettype', "metamask");
            window.location.reload();
          } else {
            toastAlert('error', "Connect BSC Network", 'excerciseerr');
            setaccount("")
            return false;
          }
        }
      });
    } catch (err) {
    }
  }

  window.addEventListener('load', async (event) => {
      event.preventDefault();
      var provider = window.ethereum;
      var web3 = new Web3(provider);
      if (window.ethereum) {
        window.ethereum.on('accountsChanged', async function (accounts) {
          if (timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = setTimeout(async () => {
            var result = await web3.eth.getAccounts()
            var setacc = result[0];
            localStorage.setItem("account", result[0])
            localStorage.setItem('wallettype', "metamask");
            window.location.href="/";
          }, 1000);
        })

        window.ethereum.on('networkChanged', async function (networkId) {
          var provider = window.ethereum;
          var web3 = new Web3(provider);
          if (networkId == config.networkversion) {
            if (timerRef.current) {
              clearTimeout(timerRef.current);
            }
            timerRef.current = setTimeout(async () => {
              var result = await web3.eth.getAccounts()
              var setacc = result[0];
              localStorage.setItem("account", result[0])
              localStorage.setItem('wallettype', "metamask");
              props.Set_Accounts(setacc);
              window.location.href="/";
            }, 1000);
          }
          else {
            localStorage.clear()
            window.location.href="/";
          }
        })
      }
  })

  async function logout() {
    localStorage.clear();
    disconnect();
    setTimeout(function(){
      window.location.href = "/";
     },700)
  }

  async function connectAccount(connector){
    try {
      let check = isMobile();
      const resultw = browser();
      var isMatamask = (connector && connector.connector && connector.connector.id) ? connector.connector.id : ""
      if (check && !window.ethereum && isMatamask == "metaMask" && resultw.name != "safari") {
        connectMetamaskMobile();
        return;
      } else {
        let rec = await connect(connector);
      }
      window.$('#connect_wallet_modal').modal('hide');
    } catch (err) {
    }
  }

  const connectMetamaskMobile = () => {
    const dappUrl = window.location.href.split("//")[1].split("/")[0];
    console.log('dappaaaaaaaaaaaaaaaaUrl: ', dappUrl);
    const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
    window.open(metamaskAppDeepLink, "_self");
  };

  function isMobile() {
    let check = false;
    (function (a) {
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };


  return (
    <>
      <nav className="navbar navbar-expand-lg bg-light main_navbar">
        <div className="container">
          {data && localStorage.getItem("account") && data.address && data.address != undefined && data.address != null && data.address != ""  ?
            <Link className="navbar-brand" to="/">
              <img src={require("../assets/images/logo.png")} className='img-fluid brand_logo' alt='logo' />
            </Link>
            :
            <Link className="navbar-brand" to="/">
              <img src={require("../assets/images/logo.png")} className='img-fluid brand_logo' alt='logo' />
            </Link>
          }
          { data && localStorage.getItem("account") && data.address && data.address != undefined && data.address != null && data.address != "" ?
              <div className='ms-auto navbar_right'>
                <div className="dropdown me-3">
                  <a className="primary_btn dropdown-toggle address_btn" href="#" role="button" id="dropdownMenuLink"  aria-expanded="false">
                    <img src={require("../assets/images/wallet_icon.png")} className='img-fluid wallet_icon' alt='Icon' />{data.address}
                  </a>
                </div>
                <button className='primary_btn' onClick={() => logout()}><img src={require("../assets/images/logout_btn.png")} className='img-fluid wallet_icon' alt='Logout' /> Logout</button>
                {/* <button type="button" onClick={() => logout()} className="primary_btn"><img src={require("../assets/images/wallet_icon.png")} className='img-fluid wallet_icon' alt='Icon' />{data.address}</button> */}
              </div>

              : status &&
              <div className='ms-auto navbar_right'>
                <button className="primary_btn" data-bs-toggle="modal" data-bs-target="#connect_wallet_modal"><img src={require("../assets/images/wallet_icon.png")} className='img-fluid wallet_icon' alt='Icon' />Connect Wallet</button>
              </div>
          }
        </div>
      </nav>
      {/* Connect Wallet Modal */}
      <div className="modal fade primary_modal" id="connect_wallet_modal" tabIndex={-1} aria-labelledby="connect_walletModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">Connect Wallet</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                {connectors && connectors.length>0 && connectors.map((connector,index)=>{
                  var logo  = "metamask.png";
                  var name = "MetaMask";
                  if (connector.name === "MetaMask") {
                      logo = "metamask.png";
                      name = "MetaMask";
                  } else if (connector.name === "WalletConnect") {
                      logo = "trustwallet.png";
                      name = "Trust wallet";
                  }
                  return(
                  <a target="_blank" className="mb-3 d-block" onClick={()=>connectAccount({connector})}>
                    <button type="button" className="connect_wallet_button">
                      <img src={require(`../assets/images/${logo}`)} height="28" width="28" className="img-fluid" /> <span>{name}</span>
                    </button>
                  </a>
                  )
                  })}
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}