import React from 'react';
import config from "../config/config";
import { Link } from "react-router-dom";

export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className='footer_panel'>
          <div className='footer_panel_left'>
            <p>Smart-contract address: </p>
            <a href={config.bscurl+"address/"+config.Binancepaycontract} target="_blank">{config.Binancepaycontract}</a>           
            <p>Copy right © 2023 Britance.com, All rights Reserved</p>
          </div>
         <div className='footer_panel_right'>
            {/* <ul className='list-unstyled'>
              <li><Link to="/terms">Terms & Privacy Policy</Link></li>
            </ul> */}
            <ul className='footer_social_links'>
              <li><a href="/" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
              <li><a href="/" target="_blank"><i className="fab fa-twitter"></i></a></li>
              <li><a href="/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
              <li><a href="#" target="_blank"><i className="fab fa-telegram-plane"></i></a></li>
              {/* <li><a href="#" target="_blank"><i className="fab fa-telegram-plane"></i></a></li> */}
            </ul> 
            
          </div>
        </div>
      </div>
    </footer>
  );  
}