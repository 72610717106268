import axios from 'axios';
import config from '../config/config';
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import binanceapi from "../ABI/binancepayABI";
import { toastAlert } from "../helper/toastAlert";

import store from "../store";

export const updateregister = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/register`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const checkuser = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/checkuser`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const get_planAlist = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/planAlist`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const get_eligible = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/getelligibles`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const get_weekcount = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/getweekcount`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}


export const updatemissed = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/register`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}


export const get_emergencydistributedetails = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/Emergencydistribute`,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const canceldistribute = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/canceldistribute`,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const updatedistribute = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/updatedistribute`,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const get_distributedetails = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/distributedetails`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const getrecords = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/getrecords`,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const levelupgrade = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/levelupgrade`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};


export const get_levelcounts = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/getlevelcount`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const getCurAddr = async () => {
  try{
    var reduxVal = store.getState();
    var transfort = (reduxVal && reduxVal.walletConnection&& reduxVal.walletConnection && reduxVal.walletConnection.web3 && reduxVal.walletConnection.web3.key)?reduxVal.walletConnection.web3:{}
    console.log(transfort,'transfort2222222')
    if(transfort && transfort!=null && transfort!=undefined && transfort!=""){
      var web3  =new Web3(transfort) 
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      let bnbbalance = await web3.eth.getBalance(result[0]);
      if(network == config.networkVersion){
        console.log("hiiiiiiii")
        var binanceContract = new web3.eth.Contract(binanceapi, config.Binancepaycontract);
        let userdetails = await binanceContract.methods.users(result[0]).call();
        localStorage.setItem("account", result[0]);
        var data = {
           address : result[0],
           provider : transfort,
           bnbvalue  : bnbbalance,
           userdetails : userdetails
        }
        return data
      }else{
        toastAlert('error', "Please select BSC chain network", 'network');
        localStorage.clear();
        return {
        }
      }
      
    }else{
      return {

      }
    }
  }catch(err){
    console.log(err,'mmmmmmmmmmmmmmmm')
    return {
      
  }
}
  
  
  
}

export const getCurAddrOld = async () => {

  if(localStorage.getItem("wallettype") && localStorage.getItem("wallettype")!=null && localStorage.getItem("wallettype")!=undefined && localStorage.getItem("wallettype")!="" && localStorage.getItem("wallettype")=="trust"){
    try{
      var web3 = new Web3(window.ethereum);
      const provider = new WalletConnectProvider({
        rpc: {
          56: "https://bsc-dataseed.binance.org/",
        },
        chainId: 56
      });

      provider.on("connect", () => {
      });
      await provider.enable();
      web3 = new Web3(provider);
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      if(result[0]!=undefined){
        if (config.networkVersion  == network) {
          localStorage.setItem("wallettype","trust")
          localStorage.setItem("account",result[0]);
          let bnbbalance = await web3.eth.getBalance(result[0]);
          var binanceContract = new web3.eth.Contract(binanceapi, config.Binancepaycontract);
          let userdetails = await binanceContract.methods.users(result[0]).call();
          var data = {
             address : result[0],
             provider : provider,
             bnbvalue  : bnbbalance,
             userdetails : userdetails
          }
          return data
        } else {
         await provider.disconnect();
        }
     }
    }catch(err){
      localStorage.clear();
      window.location.reload()
    }
  }
  else{
   if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== "undefined") {
          await window.ethereum.enable()
          let web3 = new Web3(window.ethereum);
          if (window.ethereum) {
            let netid = await web3.eth.net.getId();
            if (
              netid ==
              config.networkVersion
            ) {
              var result = await web3.eth.getAccounts();
              let bnbbalance = await web3.eth.getBalance(result[0]);
              var binanceContract = new web3.eth.Contract(binanceapi, config.Binancepaycontract);
              let userdetails = await binanceContract.methods.users(result[0]).call();
              if(localStorage.getItem("account")){
                localStorage.setItem("wallettype","metamask")
                localStorage.setItem("account",result[0]);
              }
              var data = {
                 address : result[0],
                 bnbvalue  : bnbbalance,
                 userdetails : userdetails
              }
              return data 
            }
          }
        }
      }catch(err){
        localStorage.clear();
        console.log(err,"===========errrrrrrrrrrrdata")
     }
    }
  }
}